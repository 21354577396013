import { activeMarkers, markers } from "./markers";




const chainAreas = [
  {
    title: "BNB Chain Area",
    marker: markers.bnbMarker,
    activeMarker: activeMarkers.bnbMarker,
    icon: "https://cdn.worldofdypians.com/wod/bnbIcon.svg",
    location: [-0.06735561726792588, 0.08666753768920898],
    banner: "bnbBanner.png",
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },
      {
        title: "Maze Day",
        image: "https://cdn.worldofdypians.com/wod/mazeGardenInfo.png",
        link: "/account/challenges/maze-day",
      },
      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/BNBCHAIN",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/bnbchain",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/bnbchain",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.bnbchain.org/en",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "Available",
    desc: "BNB Chain is a leading blockchain ecosystem designed to support the growing demands of the decentralized web (Web3). ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],

    area: [
      [
        [-0.06702838799312999, 0.08624374866485597],
        [-0.0668513623189673, 0.08691966533660889],
        [-0.067521914111659, 0.08707523345947267],
        [-0.0676774821262475, 0.08641004562377931],
      ],
    ],
  },
  {
    title: "CORE Area",
    marker: markers.coreMarker,
    activeMarker: activeMarkers.coreMarker,
    icon: "https://cdn.worldofdypians.com/wod/core.svg",
    location: [-0.06862698344579729, 0.08752048015594482],
    banner: "coreBanner.png",
    area: [
      [
        [-0.06825147445161225, 0.08750975131988527],
        [-0.0682890253511567, 0.08732199668884279],
        [-0.06897030595220494, 0.08749365806579591],
        [-0.0688952041541338, 0.08769750595092775],
        [-0.06869135641594512, 0.08769214153289796],
        [-0.06863234785998158, 0.08797109127044678],
        [-0.06834266949333036, 0.0878959894180298],
        [-0.06842850012066062, 0.08762240409851074],
      ],
    ],
    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },

      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/Coredao_Org",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/CoreDAOTelegram",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/coredaoofficial",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://coredao.org/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "250x250",
    rewards: "Available",
    desc: "Core is the first Bitcoin-aligned EVM-compatible Layer-1 blockchain, designed to be Bitcoin's complementary and hyper-scalable smart contract platform. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],
  },
  {
    title: "Matchain Area",
    marker: markers.matchainMarker,
    activeMarker: activeMarkers.matchainMarker,

    icon: "https://cdn.worldofdypians.com/wod/matchainIcon.svg",
    location: [-0.06703375240749239, 0.08581995964050293],
    banner: "matchainBanner.png",
    area: [
      [
        [-0.0668191758326784, 0.08607208728790285],
        [-0.06696937943514361, 0.0854766368865967],
        [-0.06716249835192278, 0.08551955223083497],
        [-0.06697474384951874, 0.08613646030426027],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },
      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/matchain_io",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/matchain_fam",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      // {
      //   link: "https://discord.com/invite/X3fHfpvpCM",
      //   icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      // },
      {
        link: "https://www.matchain.io/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "250x250",
    rewards: "N/A",
    desc: "Matchain is an AI-powered blockchain advancing data and identity sovereignty through data aggregation, analytics, and profiling for improved decentralized identity solutions. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Coingecko Area",
    marker: markers.coingeckoMarker,
    activeMarker: activeMarkers.coingeckoMarker,
    icon: "https://cdn.worldofdypians.com/wod/coingeckoIcon.svg",
    location: [-0.06883619559843743, 0.08705377578735353],
    banner: "coingeckoBanner.png",
    area: [
      [
        [-0.06848750867686589, 0.08663535118103029],
        [-0.06912587396209893, 0.08679628372192383],
        [-0.06894348388147434, 0.0874614715576172],
        [-0.06832121183646599, 0.08728981018066406],
      ],
    ],

    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/coingecko",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/coingecko",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/EhrkaCH",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.coingecko.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "CoinGecko offers fundamental analysis of the digital currency market, tracking price, volume, market cap, community growth, open-source development, major events, and on-chain metrics. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Base Area",
    marker: markers.baseMarker,
    activeMarker: activeMarkers.baseMarker,

    icon: "https://cdn.worldofdypians.com/wod/base.svg",
    location: [-0.0694799252930712, 0.08724689483642578],
    banner: "baseBanner.png",
    area: [
      [
        [-0.06921170458802975, 0.08680164813995363],
        [-0.06985006986351756, 0.08696794509887697],
        [-0.06966231537162208, 0.08763313293457033],
        [-0.06904540775014885, 0.0874561071395874],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },
      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/base",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      // {
      //   link: "https://t.me/bnbchain",
      //   icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      // },
      {
        link: "https://discord.com/invite/buildonbase",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.base.org/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "Available",
    desc: "A Layer 2 blockchain solution focusing on delivering scalable, low-cost, and user-friendly decentralized applications, with a commitment to enhancing the Web3 ecosystem. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],
  },
  {
    title: "Coin98 Area",
    marker: markers.coin98Marker,
    activeMarker: activeMarkers.coin98Marker,

    icon: "https://cdn.worldofdypians.com/wod/coin98Icon.svg",
    location: [-0.06973741716847187, 0.08653879165649415],
    banner: "coin98Banner.png",
    area: [
      [
        [-0.06938873025352861, 0.08608818054199219],
        [-0.07003245994063083, 0.08624911308288576],
        [-0.06986079869160625, 0.08689284324645997],
        [-0.06922779783037905, 0.08672654628753663],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/coin98_wallet",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/coin98wallet",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/ytkeARXYu9",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://coin98.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "Coin98 is an open, decentralized crypto wallet offering essential tools and infrastructure for users to access and participate in opportunities on the Open Internet. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Immutable Area",
    marker: markers.immutableMarker,
    activeMarker: activeMarkers.immutableMarker,

    icon: "https://cdn.worldofdypians.com/wod/immutable.svg",
    location: [-0.0671697067836984, 0.08526742458343507],
    banner: "immutableBanner.png",
    area: [
      [
        [-0.06700693033569312, 0.08538544178009033],
        [-0.06708203213669037, 0.08505284786224365],
        [-0.06725905781002611, 0.08509576320648193],
        [-0.06716249835192278, 0.08542835712432861],
      ],
    ],
    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/Immutable",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/immutablex",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/CYsjMdctsP",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.immutable.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "Immutable is the leading Layer 2 for NFTs and blockchain gaming, secured by Ethereum. Blockchain game developers - build & scale faster here. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "SKALE Area",
    marker: markers.skaleMarker,
    activeMarker: activeMarkers.skaleMarker,
    icon: "https://cdn.worldofdypians.com/wod/skaleIcon.svg",
    location: [-0.0672560036280549, 0.08460395698547363],
    banner: "skaleBanner.png",
    area: [
      [
        [-0.06724296456701524, 0.0847148895263672],
        [-0.0671410406945368, 0.08460223674774171],
        [-0.0671732271806094, 0.08445739746093751],
        [-0.06730733753899508, 0.08441448211669922],
        [-0.06746826996858207, 0.0845003128051758],
        [-0.06743608348271303, 0.08465588092803955],
        [-0.06733952402495319, 0.08472561836242677],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },

      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/SkaleNetwork",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/skaleofficial",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/gM5XBy6",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://skale.space/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    areaSize: 4,
    size: "500x500",
    lookFeel: "Futuristic",
    desc: "SKALE is a limitlessly scalable, fast, on-demand blockchain network with zero gas fees, allowing quick deployment of interoperable EVM-compatible chains. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],
  },
  {
    title: "MEXC Area",
    marker: markers.mexcMarker,
    activeMarker: activeMarkers.mexcMarker,
    icon: "https://cdn.worldofdypians.com/wod/mexcIcon.svg",
    location: [-0.06727163065611323, 0.0842052698135376],
    banner: "mexcBanner.png",
    area: [
      [
        [-0.06709276096540244, 0.08439302444458008],
        [-0.06722687132400439, 0.08443593978881837],
        [-0.06730733753899508, 0.08441984653472902],
        [-0.06741999023976578, 0.08447349071502686],
        [-0.06750045645443842, 0.0841730833053589],
        [-0.06717859159495908, 0.08407652378082275],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/MEXC_Official",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/MEXCEnglish",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/AsDwGJ5T95",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.mexc.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "MEXC is a top-tier crypto exchange offering comprehensive trading features, advanced security, and a wide range of digital assets, catering to both retail and institutional investors. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Midle Area",
    marker: markers.midleMarker,
    activeMarker: activeMarkers.midleMarker,
    icon: "https://cdn.worldofdypians.com/wod/midle.svg",
    location: [-0.06773297028649591, 0.08424818515777588],
    banner: "midleBanner.png",
    area: [
      [
        [-0.067521914111659, 0.0843501091003418],
        [-0.06759165149756868, 0.08407115936279298],
        [-0.06792424518284669, 0.08415162563323976],
        [-0.06783305014033982, 0.08444130420684816],
      ],
    ],
    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/midle_official",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/midlecommunity",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.gg/midle",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://app.midle.io/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: " Midle is the web and mobile app that boosts user acquisition, retention and engagement. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "KuCoin Area",
    marker: markers.kucoinMarker,
    activeMarker: activeMarkers.kucoinMarker,

    icon: "https://cdn.worldofdypians.com/wod/kucoinIcon.svg",
    location: [-0.06778661442929296, 0.08464515209198],
    banner: "kucoinBanner.png",
    area: [
      [
        [-0.06743071906840152, 0.08473098278045656],
        [-0.06749509204012691, 0.08443057537078857],
        [-0.06815491499548088, 0.08459687232971191],
        [-0.06806908436765445, 0.08490800857543945],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/KuCoinCom",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/Kucoin_Exchange",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/kucoinofficialserver",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.kucoin.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "KuCoin is a secure cryptocurrency exchange that allows you to buy, sell, and trade Bitcoin, Ethereum, and 700+ altcoins. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Avalanche Area",
    marker: markers.avaxMarker,
    activeMarker: activeMarkers.avaxMarker,
    icon: "https://cdn.worldofdypians.com/wod/avaxIcon.svg",
    location: [-0.06854299683618559, 0.08485436439514162],
    banner: "avalancheBanner.png",
    area: [
      [
        [-0.0681495505812457, 0.08491873741149904],
        [-0.0682192879662521, 0.08461296558380128],
        [-0.0688952041541338, 0.0847846269607544],
        [-0.06880400911347165, 0.08508503437042238],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/avax",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/avalancheavax",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/avax",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.avax.network/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "250x250",
    rewards: "N/A",
    desc: "Avalanche is a blockchain platform that aims to address the blockchain trilemma of scalability, security and decentralization. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "GateIo Area",
    marker: markers.gateMarker,
    activeMarker: activeMarkers.gateMarker,
    icon: "https://cdn.worldofdypians.com/wod/gateIcon.svg",
    location: [-0.06845180179486188, 0.08445203304290771],
    banner: "gateioBanner.png",
    area: [
      [
        [-0.06825147445161225, 0.08453786373138428],
        [-0.06831584742224353, 0.08426427841186523],
        [-0.0685947969607043, 0.08434474468231203],
        [-0.06851969516204794, 0.08462369441986085],
      ],
    ],
    events: [],
    pastEvents: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    socials: [
      {
        link: "https://x.com/gate_io",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/gateio_en",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.gg/QZWFkuQvzP",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.gate.io/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "Gate is a globally recognized cryptocurrency exchange platform, offering a secure and innovative trading experience, supporting a broad array of digital assets.",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Easy2Stake Area",
    marker: markers.easy2stakeMarker,
    activeMarker: activeMarkers.easy2stakeMarker,
    icon: "https://cdn.worldofdypians.com/wod/easy2stakeIcon.svg",
    location: [-0.06881121754499282, 0.08454322814941408],
    banner: "easy2stakeBanner.png",
    area: [
      [
        [-0.0685947969607043, 0.08462905883789064],
        [-0.06865916993087758, 0.0843554735183716],
        [-0.06897567036633834, 0.08443057537078857],
        [-0.06888983973998769, 0.08470952510833742],
      ],
    ],
    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/easy2stake",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/easy2stake",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      // {
      //   link: "https://discord.com/invite/bnbchain",
      //   icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      // },
      {
        link: "https://www.easy2stake.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "Easy2Stake is a trusted staking service provider that enables secure and efficient staking for multiple blockchains, ensuring users can earn rewards while supporting web3 ecosystems. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Chainlink Area",
    marker: markers.chainlinkMarker,
    activeMarker: activeMarkers.chainlinkMarker,

    icon: "https://cdn.worldofdypians.com/wod/chainlinkIcon.svg",
    location: [-0.06912771797944854, 0.0847846269607544],
    banner: "chainlinkBanner.png",
    area: [
      [
        [-0.06902395009360253, 0.08444666862487794],
        [-0.06931362845611903, 0.08451640605926514],
        [-0.06913123837621961, 0.08517622947692871],
        [-0.06885765325506008, 0.08509039878845216],
      ],
    ],
    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/chainlink",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/chainlinkofficial",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/chainlink",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://chain.link/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "250x250",
    rewards: "N/A",
    desc: "Chainlink connects existing systems to any public or private blockchain and enables secure cross-chain communication. World-class developer experience. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Viction Area",
    marker: markers.victionMarker,
    activeMarker: activeMarkers.victionMarker,

    icon: "https://cdn.worldofdypians.com/wod/viction.svg",
    location: [-0.07002709552661193, 0.08453786373138428],
    banner: "victionBanner.png",
    area: [
      [
        [-0.06978569689494729, 0.08455932140350342],
        [-0.06981788337923879, 0.08439302444458008],
        [-0.07016120587699533, 0.08447349071502686],
        [-0.07009683290887032, 0.08467197418212892],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },

      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/BuildOnViction",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/buildonviction",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/viction",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.viction.xyz/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "Available",
    desc: "Viction is a people-centric layer-1 blockchain that offers zero-gas transactions and enhanced security to make Web3 easy and safe for everyone. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],
  },
  {
    title: "Taiko Area",
    marker: markers.taikoMarker,
    activeMarker: activeMarkers.taikoMarker,
    icon: "https://cdn.worldofdypians.com/wod/taiko.svg",
    location: [-0.06942812516951939, 0.08510112762451173],
    banner: "taikoBanner.png",
    area: [
      [
        [-0.06921170458802975, 0.08519768714904785],
        [-0.06937800142532542, 0.08453249931335449],
        [-0.06968913744193285, 0.08460223674774171],
        [-0.06952284060573127, 0.08527278900146486],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },
      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/taikoxyz",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/TaikoEcosystem",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/taikoxyz",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://taiko.xyz/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "Available",
    desc: "Taiko is a fully decentralized, Ethereum-equivalent ZK-Rollup (Type 1 ZK-EVM). The goal is to scale Ethereum while upholding the root principles of security and decentralization. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],
  },
  {
    title: "Cookie3 Area",
    marker: markers.cookieMarker,
    activeMarker: activeMarkers.cookieMarker,
    icon: "https://cdn.worldofdypians.com/wod/cookie3.svg",
    location: [-0.0680959064388685, 0.0843554735183716],
    banner: "cookie3Banner.png",
    area: [
      [
        [-0.06791888076858607, 0.08444130420684816],
        [-0.06798861815392325, 0.08418381214141846],
        [-0.0682675676942669, 0.08424818515777588],
        [-0.0681871014808792, 0.08451104164123535],
      ],
    ],
    events: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/cookie3_com",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/cookie3_co",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/cookie3com",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.cookie3.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "Available",
    desc: "Cookie3 Score is a decentralized identity and reputation system for Web3. It allows users to build trust and reputation across applications. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "MultiversX Area",
    marker: markers.multiversxMarker,
    activeMarker: activeMarkers.multiversxMarker,

    icon: "https://cdn.worldofdypians.com/wod/multiversx.svg",
    location: [-0.0696194000590765, 0.08443593978881837],
    banner: "multiversxBanner.png",
    area: [
      [
        [-0.06938873025352861, 0.08447349071502686],
        [-0.06942091673808727, 0.08428573608398438],
        [-0.06978033248090294, 0.08438229560852052],
        [-0.0696998662700597, 0.084570050239563],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/MultiversX",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/MultiversX",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/multiversxbuilders",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://multiversx.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "MultiversX is a distributed blockchain network for next-gen applications. Decentralized via 3000+ nodes, scalable through sharding, fast, secure & green. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Conflux Area",
    marker: markers.confluxMarker,
    activeMarker: activeMarkers.confluxMarker,

    icon: "https://cdn.worldofdypians.com/wod/confluxIcon.svg",
    location: [-0.06990907841795445, 0.08591651916503908],
    banner: "confluxBanner.png",
    area: [
      [
        [-0.06940482349580157, 0.086018443107605],
        [-0.06956575591835319, 0.08535325527191163],
        [-0.07021485001704436, 0.08553028106689453],
        [-0.07004318876868135, 0.08616864681243896],
      ],
    ],
    events: [],
    pastEvents: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    socials: [
      {
        link: "https://x.com/Conflux_Network",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/Conflux_English",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/confluxnetwork",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://confluxnetwork.org/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "Conflux Network is a blockchain-based platform that connects creators, communities, and markets across different protocols. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Manta Area",
    marker: markers.mantaMarker,
    activeMarker: activeMarkers.mantaMarker,

    icon: "https://cdn.worldofdypians.com/wod/manta.png",
    location: [-0.07001821071588557, 0.08503675460815431],
    banner: "mantaBanner.png",
    area: [
      [
        [-0.06961403564501943, 0.08528888225555421],
        [-0.06976960365280153, 0.08463442325592042],
        [-0.07041333333468765, 0.08480072021484376],
        [-0.07022021443105055, 0.08544981479644775],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },

      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/mantanetwork",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://www.t.me/mantanetworkofficial",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/mantanetwork",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://manta.network/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "Available",
    desc: "Manta Network is a multi-modular blockchain ecosystem designed to support the development and deployment of zero-knowledge (ZK) applications. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Earn rewards by completing daily tasks",
      "Explore an immersive environment ",
      "Experience real-time interactions",
      "Get ranked in dedicated leaderboards",
    ],
  },
  {
    title: "BabyDoge Area",
    marker: markers.babydogeMarker,
    activeMarker: activeMarkers.babydogeMarker,
    icon: "https://cdn.worldofdypians.com/wod/babydogeIcon.svg",
    location: [-0.06669579430174531, 0.08574485778808595],
    banner: "babyDogeBanner.png",
    area: [
      [
        [-0.06632564970708857, 0.0857287645339966],
        [-0.06637392943698626, 0.08555710315704347],
        [-0.06693719294894378, 0.0856912136077881],
        [-0.06687281997648051, 0.08589506149291994],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/babydogecoin",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/babydogearmy",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/babydogecoin1",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://babydoge.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "Baby Doge was created initially as a joke meme coin with a purpose to help save dogs and spread awareness of animal adoption. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "DogeCoin Area",
    marker: markers.dogecoinMarker,
    activeMarker: activeMarkers.dogecoinMarker,
    icon: "https://cdn.worldofdypians.com/wod/dogecoinIcon.svg",
    location: [-0.06900249243705621, 0.08157670497894287],
    banner: "dogecoinBanner.png",
    area: [
      [
        [-0.06869672083011669, 0.08172154426574707],
        [-0.06879864469931281, 0.08129775524139406],
        [-0.06924389107271563, 0.08141577243804933],
        [-0.06911514513384485, 0.0818610191345215],
      ],
    ],
    events: [],
    pastEvents: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    socials: [
      {
        link: "https://x.com/dogecoin",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      // {
      //   link: "https://t.me/bnbchain",
      //   icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      // },
      {
        link: "https://discord.com/invite/X3fHfpvpCM",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://dogecoin.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "Dogecoin was originally created as a meme and evolved into a widely-used cryptocurrency, known for its supportive community and its use in charitable donations across the web. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "CoinMarketCap Area",
    marker: markers.cmcMarker,
    activeMarker: activeMarkers.cmcMarker,

    icon: "https://cdn.worldofdypians.com/wod/cmcIcon.svg",
    location: [-0.06756482942607475, 0.08595407009124757],
    banner: "coinmarketcapBanner.png",
    area: [
      [
        [-0.06704448123620446, 0.08616864681243896],
        [-0.06720541366665657, 0.08551418781280519],
        [-0.06789742311154359, 0.08568048477172853],
        [-0.06770430419767781, 0.08635640144348145],
      ],
    ],
    events: [],
    pastEvents: [
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    socials: [
      {
        link: "https://x.com/CoinMarketCap",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/CoinMarketCapAnnouncements",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      // {
      //   link: "https://discord.com/invite/X3fHfpvpCM",
      //   icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      // },
      {
        link: "https://coinmarketcap.com/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "CoinMarketCap is the world's most-referenced price-tracking website for cryptoassets in the rapidly growing cryptocurrency space. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Ordify Area",
    marker: markers.ordifyMarker,
    activeMarker: activeMarkers.ordifyMarker,

    icon: "https://cdn.worldofdypians.com/wod/ordify.svg",
    location: [-0.06787060104021504, 0.08728981018066406],
    banner: "ordifyBanner.png",
    area: [
      [
        [-0.06764529564051838, 0.08725762367248537],
        [-0.0676774821262475, 0.08715033531188966],
        [-0.06810127085310366, 0.08725762367248537],
        [-0.06804226229642768, 0.08736491203308107],
      ],
    ],
    events: [],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/ordifyworld",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/ordifyann",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      // {
      //   link: "https://discord.com/invite/X3fHfpvpCM",
      //   icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      // },
      {
        link: "https://ordify.world/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "125x125",
    rewards: "N/A",
    desc: "Ordify is a curated collection designed to enhance user experience across various blockchains, connecting businesses with the community in the Bitcoin and EVM ecosystems. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
  {
    title: "Sei Area",
    marker: markers.seiMarker,
    activeMarker: activeMarkers.seiMarker,

    icon: "https://cdn.worldofdypians.com/wod/seiLogo.svg",
    location: [-0.06734488843929015, 0.08361518383026124],
    banner: "seiBanner.png",
    area: [
      [
        [-0.0671249474515005, 0.08401215076446535],
        [-0.06698547267824354, 0.08378148078918458],
        [-0.06696937943514361, 0.08344352245330812],
        [-0.06715176952323615, 0.0832289457321167],
        [-0.06741999023976578, 0.08318603038787842],
        [-0.06768284654053355, 0.08323967456817628],
        [-0.06777940599760639, 0.08340060710906984],
        [-0.06779013482615308, 0.08364737033843994],
      ],
    ],
    events: [
      {
        title: "Daily Bonus",
        image: "https://cdn.worldofdypians.com/wod/dailyBonusInfo.png",
        link: "/account#dailybonus",
      },

      {
        title: "Leaderboard",
        image: "https://cdn.worldofdypians.com/wod/leaderboardInfo.png",
        link: "/account#leaderboard",
      },
      {
        title: "Treasure Hunt",
        image: "https://cdn.worldofdypians.com/wod/treasureHuntInfo.png",
        link: "/account/challenges/treasure-hunt",
      },
    ],
    pastEvents: [],
    socials: [
      {
        link: "https://x.com/SeiNetwork",
        icon: "https://cdn.worldofdypians.com/wod/twitterMap.svg",
      },
      {
        link: "https://t.me/seinetwork",
        icon: "https://cdn.worldofdypians.com/wod/telegramMap.svg",
      },
      {
        link: "https://discord.com/invite/sei",
        icon: "https://cdn.worldofdypians.com/wod/discordMap.svg",
      },
      {
        link: "https://www.sei.io/",
        icon: "https://cdn.worldofdypians.com/wod/websiteMap.svg",
      },
    ],
    city: "Dypians",
    size: "500x500",
    rewards: "N/A",
    desc: "Sei is at the pinnacle of high-speed blockchain platforms, ideal for web2 like experiences, high-frequency trading and real-time digital exchanges. ",
    benefits: [
      "Participate in exclusive quests and events",
      "Participate in daily challenges",
      "Explore an immersive environment",
      "Experience real-time interactions",
      "Join community-driven contests",
    ],
  },
];

const bearAreas = [
  {
    title: "Sherwood Forest",
    location: [-0.068364127150182, 0.07230162620544435],
    marker: markers.bearMarker,
  },
  {
    title: "Cedar Groove",
    location: [-0.06817100823818639, 0.07427573204040529],
    marker: markers.bearMarker,
  },
];
const deerAreas = [
  {
    title: "Kruger Creek",
    location: [-0.07038114685080327, 0.07243573665618898],
    marker: markers.deerMarker,
  },
  {
    title: "Flaming Field",
    location: [-0.07046697747444408, 0.0741201639175415],
    marker: markers.deerMarker,
  },
];
const boarAreas = [
  {
    title: "Water Swamp",
    location: [-0.06880400911347165, 0.07497310638427736],
    marker: markers.boarMarker,
  },
  {
    title: "Burning Man",
    location: [-0.06976423923874447, 0.07502138614654542],
    marker: markers.boarMarker,
  },
];
const mines = [
  {
    title: "Diamond Mine",
    location: [-0.06919024693155976, 0.07365882396698],
  },
  {
    title: "Gold Mine",
    location: [-0.07021485001704436, 0.07322967052459718],
  },
];

const craftingTables = [
  {
    title: "Alchemy Crafting",
    location: [-0.07105169859378926, 0.07447957992553712],
  },
  {
    title: "Weapon Crafting",
    location: [-0.07088003734850502, 0.07480144500732422],
  },
];

const animals = [...bearAreas, ...deerAreas, ...boarAreas];

const areas = [
  {
    title: "Onyx",
    location: [-0.030913798501249583, 0.07072448730468751],
  },
  {
    title: "Hypatia",
    location: [-0.07020948560305092, 0.03793716430664063],
  },
  {
    title: "Kepler Land",
    location: [-0.06729124429600966, 0.11363983154296875],
  },
  {
    title: "Caldera",
    location: [-0.11930457076893519, 0.07020950317382814],
  },
];

const seas = [
  {
    title: "Glacialis Frozen Sea",
    location: [-0.036629332982360708, 0.043945947265625003],
    color: "#3d6b80",
  },

  {
    title: "The Great Alimentum Reef",
    location: [-0.1025198918026858, 0.059226074218750003],
    color: "#3d6b80",
  },
  {
    title: "Levamen Waters",
    location: [-0.1008032811203055, 0.11170791625976564],
    color: "#3d6b80",
  },
  {
    title: "Vertigo Sea",
    location: [-0.07829938796474668, 0.07587432861328126],
    color: "#3d6b80",
  },
];

const teleports = [
  {
    title: "Teleport Station",
    location: [-0.06895421270976658, 0.0864154100418091],
    type: "teleport",
    desc: "The Teleport Station is a futuristic structure that allows players to instantly travel to different locations on the map. It acts as a hub for fast traversal, saving time and enabling quick access to key areas. Players can interact with the station, select their desired destination, and be transported instantly, often accompanied by visual effects like glowing lights or beams to enhance the immersive experience. Teleport stations are typically strategically placed in major zones, making them essential for exploration and quest progression.",
    subtitle: "Teleport Locations",
    locationPoints: [
      {
        title: "Dragon Ruins",
        image: "dragonMarker.png",
      },
      {
        title: "Cold Bite",
        image: "coldFuryMarker.png",
      },
      {
        title: "Fury Beast",
        image: "furyBeastMarker.png",
      },
      {
        title: "Wing Storm",
        image: "eagleMarker.png",
      },
      {
        title: "Scorpion King",
        image: "scorpionMarker.png",
      },
      {
        title: "Stone Eye",
        image: "cyclopsMarker.png",
      },
      {
        title: "Island Zero",
        image: "islandZeroIcon.png",
      },
      {
        title: "Genesis Land",
        image: "criticalHitMarker.png",
      },
    ],
  },
];

const dypiansTransport = [
  {
    title: "Island Zero Teleport",
    location: [-0.06903467892188205, 0.08158743381500246],
    marker: markers.teleportMarker,
    icon: "https://cdn.worldofdypians.com/wod/teleportsIcon.svg",
  },
  {
    title: "Genesis Land Teleport",
    location: [-0.07081029996742978, 0.08242964744567872],
    marker: markers.teleportMarker,
    icon: "https://cdn.worldofdypians.com/wod/teleportsIcon.svg",
  },
  {
    title: "Onyx Train Station",
    location: [-0.07013438380695172, 0.08632421493530275],
    marker: markers.trainMarker,
    icon: "https://cdn.worldofdypians.com/wod/trainIcon.svg",
  },
  {
    title: "Kepler Land Train Station",
    location: [-0.06864307668833743, 0.08873283863067628],
    marker: markers.trainMarker,
    icon: "https://cdn.worldofdypians.com/wod/trainIcon.svg",
  },
  {
    title: "Hypatia Train Station",
    location: [-0.06732879519631745, 0.08794426918029787],
    marker: markers.trainMarker,
    icon: "https://cdn.worldofdypians.com/wod/trainIcon.svg",
  },
];

const quests = [
  {
    title: "Dragon Slaying",
    location: [-0.06608961547135184, 0.085294246673584],
    conditions: [
      "8x Wolf Pelts",
      "6x Deer Horns",
      "3x Bunny Ears",
      "4x Lime Stone",
    ],
    rewards: ["50x Gold", "2x Red Gem", "1x Serrated Blade", "500x Points"],
    questGiver: "Aragon The Brave",
  },
  {
    title: "Scorpion Hunt",
    location: [-0.06945846763671572, 0.08226871490478517],
    conditions: ["8x Wolf Pelts", "6x Deer Horns", "3x Bunny Ears"],
    rewards: ["50x Gold", "2x Red Gem", "1x Serrated Blade"],
    questGiver: "Paraxys The Wicked",
  },
  {
    title: "Alchemy Reagents",
    location: [-0.07126091073560278, 0.08694648742675783],
    conditions: ["8x Wolf Pelts", "6x Deer Horns"],
    rewards: ["50x Gold", "2x Red Gem"],
    questGiver: "Richard Lionheart",
  },
  {
    title: "Portal Fuel",
    location: [-0.06817100823818639, 0.08831977844238283],
    conditions: ["8x Wolf Pelts"],
    rewards: ["50x Gold"],
    questGiver: "Napoleon Bonaparte",
  },
];

const hypatiaBorders = [
  [-0.04536151412107925, 0.04209995269775391],
  [-0.04619836306659037, 0.04463195800781251],
  [-0.04559754844003202, 0.0457906723022461],
  [-0.04583358275820873, 0.04667043685913086],
  [-0.04686355068292695, 0.04688501358032227],
  [-0.04703521200224178, 0.04825830459594727],
  [-0.04776477260464168, 0.0491166114807129],
  [-0.04774331493996587, 0.05272150039672852],
  [-0.04853724852813649, 0.05325794219970704],
  [-0.04926680911445551, 0.052850246429443366],
  [-0.048301214219107054, 0.054481029510498054],
  [-0.04855870619254514, 0.056605339050292976],
  [-0.04832267188359203, 0.05840778350830079],
  [-0.04982470838096353, 0.05911588668823243],
  [-0.050060742684598744, 0.06010293960571289],
  [-0.050983422227008586, 0.060317516326904304],
  [-0.051648609795904, 0.05990982055664063],
  [-0.053987495062927396, 0.06085395812988282],
  [-0.055682650383938694, 0.06257057189941408],
  [-0.05660532984207655, 0.06181955337524415],
  [-0.06089686200874071, 0.06566047668457033],
];

const keplerBorders = [
  [-0.05881546894907884, 0.08089542388916017],
  [-0.04881619816501633, 0.08321285247802734],
  [-0.04658460103813389, 0.08068084716796876],
  [-0.0461554477362973, 0.08576631546020509],
  [-0.0390315025658018, 0.08615255355834961],
  [-0.03890275656286193, 0.0888347625732422],
  [-0.03714322783661564, 0.09025096893310547],
  [-0.03712177016900099, 0.09220361709594728],
  [-0.03828048421257122, 0.09297609329223634],
  [-0.03765821185776412, 0.09447813034057617],
  [-0.03825902654523646, 0.09851217269897462],
  [-0.03600597144602951, 0.10185956954956056],
  [-0.03686427815724359, 0.10323286056518556],
  [-0.03798007686942663, 0.10301828384399415],
  [-0.03757238118777626, 0.10391950607299806],
  [-0.035598275755359086, 0.10492801666259767],
  [-0.03188609912311909, 0.11363983154296875],
];

const calderaBorders = [
  [-0.11248104499785845, 0.0667548179626465],
  [-0.11539928263541743, 0.06701231002807619],
  [-0.11902562163471021, 0.07299900054931642],
  [-0.11623613013835618, 0.07553100585937501],
  [-0.1223730110573374, 0.08132457733154298],
  [-0.1271795182128582, 0.08138895034790039],
];

const bosses = [
  {
    title: "Scorching Dragon",
    location: [-0.11415474014968524, 0.08462905883789064],
  },
  {
    title: "Scorpion King",
    location: [-0.02729415790322787, 0.07261276245117189],
  },
];

const regions = [
  {
    title: "Lone Islands",
    location: [-0.007853507971014917, 0.07857799530029298],
    area: [
      [
        [-0.014923810790116284, 0.08675336837768555],
        [-0.013861656053738434, 0.083770751953125],
        [-0.013915300232463016, 0.0826120376586914],
        [-0.013861656053738434, 0.07821321487426759],
        [-0.01321792590814017, 0.0783205032348633],
        [-0.012059211641918397, 0.07751584053039551],
        [-0.011243820118254096, 0.07739782333374025],
        [-0.008776187862407936, 0.07244110107421876],
        [-0.0065875053260597075, 0.07012367248535158],
        [-0.0056433677582101004, 0.07044553756713869],
        [-0.005198121063723922, 0.07227480411529542],
        [-0.003706812855985926, 0.07538080215454102],
        [-0.0032508373243019533, 0.077054500579834],
        [-0.0030738115295962393, 0.07851362228393556],
        [-0.003438591955022295, 0.07930219173431398],
        [-0.0049084424912399244, 0.08080422878265382],
        [-0.007928609822710198, 0.08254766464233398],
        [-0.011297464297525733, 0.08371710777282716],
        [-0.014290809483166148, 0.08705377578735353],
      ],
    ],
  },
  {
    title: "Upper Yards",

    location: [-0.018281936335281662, 0.04870891571044922],
    area: [
      [-0.026328562763600093, 0.050704479217529304],
      [-0.026607512471380858, 0.0454258918762207],
      [-0.027229784894162768, 0.044953823089599616],
      [-0.027315615572916237, 0.04398822784423828],
      [-0.026414393442989667, 0.042808055877685554],
      [-0.02345323497126408, 0.04261493682861329],
      [-0.02306699690495276, 0.04330158233642578],
      [-0.0204062457538916, 0.04343032836914063],
      [-0.0200629230213974, 0.04465341567993165],
      [-0.0178313252431628, 0.04476070404052735],
      [-0.015342235381837314, 0.04551172256469727],
      [-0.013153552893490017, 0.04744291305541993],
      [-0.01036405557702625, 0.04746437072753907],
      [-0.008990764581021153, 0.04956722259521485],
      [-0.01319646823659436, 0.05239963531494141],
      [-0.014655589897549318, 0.052335262298583984],
      [-0.016179084562811266, 0.0540304183959961],
      [-0.01723051045195349, 0.053129196166992194],
      [-0.01836776701936574, 0.054202079772949226],
      [-0.021157264228644813, 0.05156278610229493],
      [-0.02216577474615948, 0.051455497741699226],
      [-0.022981166223400407, 0.05055427551269531],
      [-0.02456903382345514, 0.05158424377441407],
      [-0.026221274414280425, 0.05083322525024414],
    ],
  },
  {
    title: "Arid Land",
    location: [-0.012917518506282545, 0.057635307312011726],

    area: [
      [-0.008969306909170008, 0.04955649375915528],
      [-0.006651878341994806, 0.05199193954467774],
      [-0.007166862469106647, 0.05428791046142579],
      [-0.006501674638146243, 0.06044626235961915],
      [-0.005450248710035862, 0.06149768829345704],
      [-0.00600814818233849, 0.062077045440673835],
      [-0.007789134955245207, 0.061347484588623054],
      [-0.009119510612128016, 0.06299972534179689],
      [-0.011672973552058251, 0.06289243698120119],
      [-0.0144410131832871, 0.06445884704589845],
      [-0.016865730042073986, 0.06351470947265626],
      [-0.01864671674236228, 0.06179809570312501],
      [-0.018603801400402936, 0.05945920944213868],
      [-0.020170211375373403, 0.05718469619750977],
      [-0.01974105795902413, 0.05596160888671876],
      [-0.01723051045195349, 0.05321502685546875],
      [-0.016157626891545342, 0.054094791412353516],
      [-0.014677047568967906, 0.05237817764282227],
      [-0.01321792590814017, 0.052464008331298835],
    ],
  },
  {
    title: "Acerous",
    location: [-0.030663011994557565, 0.05053281784057617],
    area: [
      [-0.01836776701936574, 0.054202079772949226],
      [-0.021157264228644813, 0.05156278610229493],
      [-0.02216577474615948, 0.051455497741699226],
      [-0.022981166223400407, 0.05055427551269531],
      [-0.02456903382345514, 0.05158424377441407],
      [-0.026221274414280425, 0.05083322525024414],
      [-0.026832818004126485, 0.0515735149383545],
      [-0.027219056059305864, 0.05102634429931641],
      [-0.027723311296142816, 0.04978179931640626],
      [-0.02720832722446168, 0.047571659088134766],
      [-0.028345583713110672, 0.045447349548339844],
      [-0.03044843530388234, 0.042850971221923835],
      [-0.03268003286550845, 0.04471778869628907],
      [-0.0327873212085815, 0.04714250564575196],
      [-0.033087728568621196, 0.04812955856323242],
      [-0.035984513778147695, 0.05094051361083985],
      [-0.038022992204223366, 0.052013397216796875],
      [-0.036370751799295144, 0.05789279937744141],
      [-0.03531932607122893, 0.061690807342529304],
      [-0.03229379482943784, 0.06059646606445313],
      [-0.03145694574631047, 0.05714178085327149],
      [-0.031778810779079146, 0.05443811416625977],
      [-0.02971887455240348, 0.05351543426513672],
      [-0.02589940936578709, 0.057592391967773444],
      [-0.022830962530569286, 0.054094791412353516],
      [-0.021972655711419903, 0.055167675018310554],
      [-0.01971960028817804, 0.05591869354248047],
      [-0.01845359770342437, 0.054202079772949226],
    ],
  },
  {
    title: "Barren Sands",
    location: [-0.02935409417485113, 0.06111145019531251],

    area: [
      [
        [-0.035308597237211685, 0.061690807342529304],
        [-0.032288430412263834, 0.06059110164642335],
        [-0.03145694574631047, 0.057136416435241706],
        [-0.03177344636187969, 0.05444347858428956],
        [-0.02971887455240348, 0.05352616310119629],
        [-0.025904773783279156, 0.05757629871368409],
        [-0.02570629033630179, 0.061267018318176276],
        [-0.027557014356573224, 0.06530106067657472],
        [-0.033624170280678704, 0.06816565990448],
        [-0.033817289296309505, 0.06618618965148927],
        [-0.03349542427005461, 0.06499528884887697],
        [-0.033860204633065466, 0.06433010101318361],
        [-0.03421425616055786, 0.06299972534179689],
      ],
    ],
  },
  {
    title: "Scorcher",
    location: [-0.02188682502923144, 0.06862163543701173],
    area: [
      [-0.006002783764337538, 0.062077045440673835],
      [-0.005450248710035862, 0.06149232387542725],
      [-0.004844069475126715, 0.061411857604980476],
      [-0.0049781799253668085, 0.062066316604614265],
      [-0.0056433677582101004, 0.0637561082839966],
      [-0.008733272518667482, 0.0683426856994629],
      [-0.009784698438762866, 0.06890058517456056],
      [-0.010010003992652821, 0.06954967975616456],
      [-0.011029243401116657, 0.06959259510040285],
      [-0.011801719582071005, 0.06897568702697755],
      [-0.012230873015022771, 0.07072448730468751],
      [-0.013260841251231793, 0.07164716720581056],
      [-0.014344453661788952, 0.07119655609130861],
      [-0.01603960969955733, 0.07244110107421876],
      [-0.01727342579424361, 0.07390022277832033],
      [-0.017552375518906763, 0.07516622543334962],
      [-0.018539428387419392, 0.07637858390808107],
      [-0.017799138736543808, 0.07709741592407228],
      [-0.021575688805875234, 0.07825613021850587],
      [-0.02191901153504893, 0.07988691329956056],
      [-0.02270221650801175, 0.08020877838134766],
      [-0.023818015366245865, 0.07677555084228517],
      [-0.026113986064871704, 0.07973670959472658],
      [-0.026221274414280425, 0.08256912231445312],
      [-0.02808809167891127, 0.08267641067504884],
      [-0.028903483118583484, 0.08149623870849611],
      [-0.02832412604361314, 0.07995128631591798],
      [-0.029976366582887992, 0.0769472122192383],
      [-0.032744405871367545, 0.07437229156494142],
      [-0.033817289296309505, 0.07259130477905275],
      [-0.032615659859585734, 0.07003784179687501],
      [-0.033624170280678704, 0.06817102432250978],
      [-0.027551649939170215, 0.06529569625854494],
      [-0.02570629033630179, 0.06128311157226563],
      [-0.025904773783279156, 0.05758702754974366],
      [-0.022830962530569286, 0.05410015583038331],
      [-0.021967291293775174, 0.05517303943634033],
      [-0.019714235870456977, 0.05591869354248047],
      [-0.02016484695767778, 0.05719006061553956],
      [-0.018603801400402936, 0.059453845024108894],
      [-0.01864671674236228, 0.06179809570312501],
      [-0.016860365624276587, 0.06352007389068605],
      [-0.014435648765426091, 0.06445348262786867],
      [-0.011678337969970148, 0.06289243698120119],
      [-0.00911414619416523, 0.06299972534179689],
      [-0.007789134955245207, 0.06135284900665284],
    ],
  },
  {
    title: "Sandy Bay",
    location: [-0.021157264228644813, 0.08484363555908203],
    area: [
      [
        [-0.01325547683334534, 0.07164716720581056],
        [-0.013647079338725606, 0.07651805877685548],
        [-0.014022588589861293, 0.07782697677612305],
        [-0.017032026993729684, 0.08609890937805177],
        [-0.016602873569823415, 0.08742928504943849],
        [-0.017970800105131794, 0.09232163429260254],
        [-0.020127296033795723, 0.09145259857177734],
        [-0.021468400452853403, 0.09233236312866212],
        [-0.025770663346168392, 0.08909225463867188],
        [-0.02828121070461807, 0.08868455886840822],
        [-0.028817652440987742, 0.08716106414794923],
        [-0.027873514983299826, 0.08632421493530275],
        [-0.028431414391049917, 0.083920955657959],
        [-0.02808809167891127, 0.08267641067504884],
        [-0.026221274414280425, 0.08256912231445312],
        [-0.026108621647405083, 0.07973670959472658],
        [-0.023818015366245865, 0.07678091526031496],
        [-0.02269685209041791, 0.08020877838134766],
        [-0.02191901153504893, 0.07989227771759035],
        [-0.021570324388230505, 0.07826685905456544],
        [-0.01780450315431576, 0.07709741592407228],
        [-0.018539428387419392, 0.07637858390808107],
        [-0.01754701110113481, 0.07516086101531984],
        [-0.017268061376458938, 0.07390558719635011],
        [-0.01604497411738017, 0.07244110107421876],
        [-0.014344453661788952, 0.07120192050933839],
      ],
    ],
  },
  {
    title: "Emerald Vale",
    location: [-0.04274367889655066, 0.04982471466064454],
    area: [
      [
        [-0.03801762778738013, 0.0520080327987671],
        [-0.03853261180332523, 0.05108535289764405],
        [-0.04010438591591167, 0.05169153213500977],
        [-0.04100560791915038, 0.0502324104309082],
        [-0.039181706235656595, 0.04782915115356445],
        [-0.03993272458083401, 0.04669189453125001],
        [-0.04255055990097007, 0.04476070404052735],
        [-0.0440740541860794, 0.04207849502563477],
        [-0.04493236081192739, 0.04207849502563477],
        [-0.04581212509296042, 0.04471778869628907],
        [-0.04518985279771875, 0.04613399505615235],
        [-0.04523276812860976, 0.0491166114807129],
        [-0.04515766629954731, 0.052421092987060554],
        [-0.04304408622202408, 0.05503892898559571],
        [-0.040125843582775704, 0.05529642105102539],
        [-0.03723442292391745, 0.054824352264404304],
      ],
    ],
  },
  {
    title: "Granitus",
    location: [-0.041627880248805656, 0.060338973999023444],
    area: [
      [
        [-0.04304408622202408, 0.055044293403625495],
        [-0.04478215715312821, 0.059652328491210944],
        [-0.04624127839687072, 0.06113290786743165],
        [-0.04671334702817343, 0.06280660629272462],
        [-0.047228330985977986, 0.06473779678344728],
        [-0.04686355068292695, 0.06844997406005861],
        [-0.044503207500256534, 0.06677627563476564],
        [-0.04495381847744287, 0.06546735763549806],
        [-0.043365951212313264, 0.062055587768554694],
        [-0.04259347523336231, 0.06368637084960939],
        [-0.04016875891647833, 0.063321590423584],
        [-0.03890275656286193, 0.06551027297973634],
        [-0.03810882287375322, 0.064694881439209],
        [-0.03862380688916398, 0.06188392639160157],
        [-0.03890275656286193, 0.05948066711425782],
        [-0.038387772549155944, 0.057914257049560554],
        [-0.03890275656286193, 0.05613327026367188],
        [-0.038999316065085915, 0.0551140308380127],
        [-0.040131207999491714, 0.05529642105102539],
      ],
    ],
  },
  {
    title: "Antares",
    location: [-0.03583431010280963, 0.06493091583251955],
    area: [
      [
        [-0.03361880586358103, 0.06817102432250978],
        [-0.03600597144602951, 0.06832122802734376],
        [-0.03669261681565215, 0.06801009178161622],
        [-0.03770112719271352, 0.068814754486084],
        [-0.03991126691391909, 0.06838560104370119],
        [-0.04137038825091546, 0.0668621063232422],
        [-0.044631953494023685, 0.0680208206176758],
        [-0.04450857191666721, 0.06677627563476564],
        [-0.04494845406107036, 0.06546199321746828],
        [-0.043365951212313264, 0.062055587768554694],
        [-0.042588110816811686, 0.06369173526763917],
        [-0.04016875891647833, 0.063321590423584],
        [-0.03889739214606958, 0.06551027297973634],
        [-0.03810882287375322, 0.06470561027526857],
        [-0.03862380688916398, 0.06185173988342286],
        [-0.03889739214606958, 0.05948066711425782],
        [-0.038387772549155944, 0.05791962146759034],
        [-0.03890275656286193, 0.05610108375549317],
        [-0.03898858723151395, 0.05511939525604249],
        [-0.03724515175770569, 0.054824352264404304],
        [-0.03531396165422031, 0.06168544292449952],
        [-0.034208891743485625, 0.06299972534179689],
        [-0.03386556905016314, 0.06431937217712404],
        [-0.03350078868716501, 0.06498992443084718],
        [-0.03382265371340718, 0.06619155406951906],
      ],
    ],
  },
  {
    title: "Orchid",
    location: [-0.03592014077446409, 0.07347106933593751],
    area: [
      [
        [-0.03769576277584485, 0.06882011890411378],
        [-0.040040012915294125, 0.07364273071289062],
        [-0.04084467541931129, 0.07659316062927247],
        [-0.039600130743092925, 0.07819175720214845],
        [-0.03800689895368095, 0.07786989212036134],
        [-0.03701448183086416, 0.07926464080810548],
        [-0.035072562888412595, 0.07929682731628418],
        [-0.03392457763816123, 0.07747292518615724],
        [-0.03313064390568248, 0.07731199264526369],
        [-0.031880734705919636, 0.07518231868743898],
        [-0.03274977028851611, 0.0743776559829712],
        [-0.033811924879211835, 0.07259130477905275],
        [-0.032615659859585734, 0.07003784179687501],
        [-0.03361880586358103, 0.06817102432250978],
        [-0.03600060702905906, 0.06832122802734376],
        [-0.03669261681565215, 0.06801545619964601],
      ],
    ],
  },
  {
    title: "Achernar City",
    location: [-0.042057033576748226, 0.06982326507568361],
    area: [
      [
        [-0.04083931100264617, 0.07658243179321289],
        [-0.0417619906640342, 0.0746995210647583],
        [-0.04162251583219142, 0.07335841655731203],
        [-0.043129916886236054, 0.07295608520507814],
        [-0.045940871084399405, 0.07150769233703615],
        [-0.04752873829294058, 0.06964087486267091],
        [-0.046858186266694385, 0.06844460964202882],
        [-0.04450857191666721, 0.06678700447082521],
        [-0.044631953494023685, 0.0680208206176758],
        [-0.04137038825091546, 0.06686747074127199],
        [-0.03990590249719035, 0.06838560104370119],
        [-0.03769576277584485, 0.06882011890411378],
        [-0.04005074174873886, 0.07366955280303956],
      ],
    ],
  },
  {
    title: "Zephyr Winds",
    location: [-0.04265784823190613, 0.08664608001708984],
    area: [
      [
        [-0.0395894019095973, 0.07819175720214845],
        [-0.04176735508064843, 0.08034825325012208],
        [-0.04368781620121573, 0.08180737495422365],
        [-0.044814343651465034, 0.08597016334533693],
        [-0.047228330985977986, 0.08584141731262207],
        [-0.048301214219107054, 0.087890625],
        [-0.05148767732018624, 0.08680701255798341],
        [-0.05219578021026562, 0.08741855621337892],
        [-0.054202071688477585, 0.0877511501312256],
        [-0.054395190650827735, 0.08866310119628908],
        [-0.05554317558086085, 0.08819103240966798],
        [-0.05544661610162591, 0.09036898612976076],
        [-0.051230185358210875, 0.09015440940856935],
        [-0.051852457597881894, 0.09234309196472168],
        [-0.049588674076463196, 0.09301900863647462],
        [-0.04686355068292695, 0.09394168853759766],
        [-0.04527568345946261, 0.09518623352050783],
        [-0.04061400550230403, 0.09476780891418457],
        [-0.041027065585772696, 0.09248256683349611],
        [-0.04093050608592776, 0.08839488029479982],
        [-0.03986835158008924, 0.08488655090332031],
        [-0.035614369006346784, 0.08396923542022706],
        [-0.03435373100435952, 0.08198976516723633],
        [-0.032213328571738656, 0.08273005485534668],
        [-0.028898118701231365, 0.08149087429046632],
        [-0.02832412604361314, 0.07996201515197755],
        [-0.02998173100018922, 0.07694184780120851],
        [-0.03187537028872019, 0.07518768310546876],
        [-0.03311991507142353, 0.07731735706329347],
        [-0.03392457763816123, 0.07747292518615724],
        [-0.03506183405436991, 0.07930219173431398],
        [-0.03701448183086416, 0.07926464080810548],
        [-0.03800689895368095, 0.07786989212036134],
      ],
    ],
  },
  {
    title: "State of Solitude",
    location: [-0.03377437395954083, 0.08660316467285156],
    area: [
      [
        [-0.028898118701231365, 0.08149623870849611],
        [-0.03222942182328613, 0.08273005485534668],
        [-0.03435373100435952, 0.08198976516723633],
        [-0.03560900458935089, 0.08397459983825684],
        [-0.03986835158008924, 0.08488655090332031],
        [-0.04093050608592776, 0.08837342262268068],
        [-0.041027065585772696, 0.09248256683349611],
        [-0.0406193699189946, 0.09476780891418457],
        [-0.03945529149075564, 0.09476780891418457],
        [-0.0390315025658018, 0.0939309597015381],
        [-0.03745436401641097, 0.09279370307922365],
        [-0.03653168430761995, 0.09241819381713869],
        [-0.0358021236009233, 0.09164571762084962],
        [-0.0331950169112617, 0.09032607078552247],
        [-0.03268003286550845, 0.090712308883667],
        [-0.02828121070461807, 0.088689923286438],
        [-0.028817652440987742, 0.087166428565979],
        [-0.027878879400702834, 0.08632421493530275],
        [-0.028431414391049917, 0.0839155912399292],
        [-0.02808809167891127, 0.08267641067504884],
      ],
    ],
  },
  {
    title: "Etherealis",
    location: [-0.04750728062820116, 0.07699012756347658],
    area: [
      [
        [-0.05418597844159917, 0.0877457857131958],
        [-0.05307554439589473, 0.08032679557800294],
        [-0.052281610863065764, 0.07718324661254883],
        [-0.05300044257566149, 0.07552027702331544],
        [-0.051793449023687405, 0.07135748863220216],
        [-0.04964231823665151, 0.06673336029052736],
        [-0.047228330985977986, 0.06473779678344728],
        [-0.046858186266694385, 0.06844460964202882],
        [-0.04752873829294058, 0.0696462392807007],
        [-0.04593014225179433, 0.07151305675506593],
        [-0.043135281302735785, 0.07295608520507814],
        [-0.04162251583219142, 0.07335305213928224],
        [-0.0417619906640342, 0.0746995210647583],
        [-0.04085003983597641, 0.07656633853912355],
        [-0.039600130743092925, 0.07819175720214845],
        [-0.04178881274706719, 0.08036434650421144],
        [-0.043693180617690015, 0.08180737495422365],
        [-0.044814343651465034, 0.08596479892730713],
        [-0.047228330985977986, 0.0858360528945923],
        [-0.048301214219107054, 0.08788526058197023],
        [-0.05148767732018624, 0.08680164813995363],
        [-0.05219041579445289, 0.08741319179534912],
      ],
    ],
  },
  {
    title: "Life River",
    location: [-0.047893518592493, 0.052099227905273444],
    area: [
      [
        [-0.04623591398058727, 0.06112217903137208],
        [-0.04935263977113593, 0.05897104740142823],
        [-0.05157887238981689, 0.05498528480529786],
        [-0.052807323608909454, 0.054416656494140625],
        [-0.0523674415157514, 0.05353689193725586],
        [-0.052281610863065764, 0.05102097988128663],
        [-0.0513535669234558, 0.05055427551269531],
        [-0.05087076949288023, 0.050082206726074226],
        [-0.05099415105881216, 0.04881620407104493],
        [-0.050758116758561046, 0.04682064056396485],
        [-0.049062961304360844, 0.04435300827026368],
        [-0.04581212509296042, 0.04471242427825928],
        [-0.04518985279771875, 0.046128630638122566],
        [-0.04523276812860976, 0.0492238998413086],
        [-0.04515766629954731, 0.05242645740509034],
        [-0.04304408622202408, 0.055044293403625495],
        [-0.04477142832034502, 0.05963623523712159],
      ],
    ],
  },
  {
    title: "Abyss",
    location: [-0.04759311128712068, 0.0418853759765625],
    area: [
      [
        [-0.04904686805617204, 0.044347643852233894],
        [-0.0494921145880301, 0.04283487796783448],
        [-0.050071471516554986, 0.04185318946838379],
        [-0.04971742006084135, 0.04025459289550782],
        [-0.048784011668511505, 0.03895640373229981],
        [-0.04757165362239398, 0.039428472518920905],
        [-0.047383899055819065, 0.03986835479736329],
        [-0.046960110175100385, 0.03976643085479737],
        [-0.045935506668103226, 0.04045307636260987],
        [-0.046203727482873824, 0.04091978073120118],
        [-0.04492699639554216, 0.04207313060760499],
        [-0.045806760676638796, 0.0447070598602295],
      ],
    ],
  },
  {
    title: "Hesperian",
    location: [-0.05214213605220192, 0.04677772521972657],
    area: [
      [
        [-0.050066107100576865, 0.04185318946838379],
        [-0.052109949557338255, 0.04257202148437501],
        [-0.05303262907006716, 0.04463195800781251],
        [-0.053869477918545926, 0.048333406448364265],
        [-0.05332230751890391, 0.04966378211975098],
        [-0.053343765181722284, 0.05160570144653321],
        [-0.05257128931541385, 0.05393385887145997],
        [-0.05236207709996411, 0.05353152751922608],
        [-0.052281610863065764, 0.05102097988128663],
        [-0.05135893133933214, 0.05055427551269531],
        [-0.05087613390879474, 0.050076842308044434],
        [-0.05099415105881216, 0.04880547523498536],
        [-0.050752752342633815, 0.04682064056396485],
        [-0.04905759688830639, 0.04434227943420411],
        [-0.049497479004059104, 0.04282414913177491],
      ],
    ],
  },
  {
    title: "Voyagers Lands",
    location: [-0.055618277397938974, 0.053472518920898444],
    area: [
      [
        [-0.053869477918545926, 0.04832804203033448],
        [-0.05596159998908932, 0.04954576492309571],
        [-0.05677699113499278, 0.04998564720153809],
        [-0.05704521190416695, 0.052142143249511726],
        [-0.05758165343876223, 0.05425572395324708],
        [-0.05813955262938221, 0.05607962608337403],
        [-0.0582039256125576, 0.05751729011535645],
        [-0.058633078831865144, 0.05784988403320313],
        [-0.05795179809468918, 0.05839169025421143],
        [-0.057195415534366585, 0.05857944488525391],
        [-0.05665360958075258, 0.05796253681182862],
        [-0.05612789686845737, 0.05719542503356934],
        [-0.055763116616320656, 0.05610108375549317],
        [-0.05429863116973535, 0.05536079406738282],
        [-0.05393385090641581, 0.054513216018676765],
        [-0.05280195919316034, 0.054416656494140625],
        [-0.05256592489963928, 0.05393385887145997],
        [-0.053343765181722284, 0.05160033702850342],
        [-0.05332230751890391, 0.04966914653778077],
      ],
    ],
  },
  {
    title: "Himdal",
    location: [-0.05390166441249359, 0.06188392639160157],
    area: [
      [
        [-0.06034432726093, 0.06924390792846681],
        [-0.06076811604858566, 0.06603598594665529],
        [-0.06222187249729155, 0.06546735763549806],
        [-0.062007295902198355, 0.06476998329162599],
        [-0.06053208178751899, 0.06465196609497072],
        [-0.059394825788039306, 0.061776638031005866],
        [-0.05850433286641765, 0.06041407585144044],
        [-0.057195415534366585, 0.05857944488525391],
        [-0.05664824516534696, 0.057967901229858405],
        [-0.05612253245300086, 0.05720615386962891],
        [-0.05574702336986208, 0.05610108375549317],
        [-0.05429863116973535, 0.05536079406738282],
        [-0.05393385090641581, 0.05451858043670655],
        [-0.052796594777411214, 0.054416656494140625],
        [-0.051589601221518684, 0.05497455596923829],
        [-0.04934727535510692, 0.058976411819458015],
        [-0.04623591398058727, 0.06111145019531251],
        [-0.047228330985977986, 0.06474316120147706],
        [-0.04965304706868408, 0.06673336029052736],
        [-0.050387972058691555, 0.06832659244537355],
        [-0.0530540867329873, 0.06728053092956544],
        [-0.053687087785874735, 0.06779551506042482],
        [-0.05703448307343204, 0.06830513477325441],
        [-0.058434595468345535, 0.0683104991912842],
      ],
    ],
  },
  {
    title: "Serpentius",
    location: [-0.055661192721947264, 0.07656097412109376],
    area: [
      [
        [-0.05038847387112626, 0.0683388267192875],
        [-0.05305458854539656, 0.0672874007854496],
        [-0.05368758959828399, 0.06780238491630898],
        [-0.0570462155288964, 0.06831351006695298],
        [-0.058440963508289784, 0.06831351006695298],
        [-0.060350695300657974, 0.0692469188041356],
        [-0.06069401786301794, 0.07165017808147935],
        [-0.06150940893987792, 0.07263723099895981],
        [-0.060350695300657974, 0.07449331963726548],
        [-0.06026486465972766, 0.07596317017742661],
        [-0.05955676186689692, 0.0782805987662938],
        [-0.05910615099400734, 0.07879558289715316],
        [-0.05871991310003119, 0.07993283951946761],
        [-0.05881647257378285, 0.08114519799419907],
        [-0.05972842315085671, 0.0812095710105565],
        [-0.05794743730411479, 0.08133831704327134],
        [-0.05645612983507971, 0.08322659218975571],
        [-0.055543677393257385, 0.08818180901146279],
        [-0.054401056878846166, 0.08865387779808388],
        [-0.05419184466963032, 0.08773656231499062],
        [-0.053076046208316706, 0.08032830101585732],
        [-0.05228211267548774, 0.07718475205040322],
        [-0.053000944388083476, 0.07551105362511025],
        [-0.05178808460786195, 0.0713413953781128],
      ],
    ],
  },
  {
    title: "Callisto",
    location: [-0.06342886582836976, 0.06042480468750001],
    area: [
      [
        [-0.06221650808243299, 0.06546735763549806],
        [-0.06280659371449604, 0.0651293992996216],
        [-0.06332157753341212, 0.06539762020111085],
        [-0.06419597713085516, 0.06491482257843019],
        [-0.06556390285140722, 0.0645875930786133],
        [-0.06575702177365693, 0.06405115127563478],
        [-0.06686209114771755, 0.06465196609497072],
        [-0.06862161903162572, 0.06349325180053712],
        [-0.06925461990094427, 0.06148695945739747],
        [-0.06837485597858875, 0.05801081657409668],
        [-0.0676774821262475, 0.05645513534545899],
        [-0.06675480286006247, 0.05583286285400391],
        [-0.06406186676411099, 0.05716323852539063],
        [-0.062849509032932, 0.05669116973876954],
        [-0.06117581158800162, 0.056937932968139655],
        [-0.05907296087825632, 0.05669116973876954],
        [-0.05862771441665036, 0.05784451961517334],
        [-0.057957162509967575, 0.05839169025421143],
        [-0.057206144365076046, 0.05857408046722413],
        [-0.05840777339214438, 0.06026923656463624],
        [-0.059394825788039306, 0.061776638031005866],
        [-0.06053744620255566, 0.06464660167694093],
        [-0.062012660317082365, 0.0647646188735962],
      ],
    ],
  },
  {
    title: "Genesis Land",
    special: true,
    location: [-0.05965231771451968, 0.09518623352050783],
    area: [
      [
        [-0.05550562467228362, 0.08902251720428468],
        [-0.05732952591813309, 0.08792281150817872],
        [-0.060097564167868926, 0.08712887763977052],
        [-0.06336449285141553, 0.09076595306396486],
        [-0.0649845460801544, 0.09142041206359865],
        [-0.06512402085903457, 0.09859800338745117],
        [-0.06325720455631796, 0.10170936584472656],
        [-0.060886133178743695, 0.10124802589416505],
        [-0.05911051178449266, 0.099751353263855],
        [-0.056594601011239865, 0.10012149810791017],
        [-0.05472778441787145, 0.09899497032165527],
        [-0.05377291843661387, 0.09619474411010744],
        [-0.0505113536255522, 0.09531497955322267],
        [-0.04943310601168556, 0.09306728839874268],
        [-0.051852457597881894, 0.09233772754669191],
        [-0.051230185358210875, 0.09015440940856935],
        [-0.055441251686105794, 0.09036362171173097],
      ],
    ],
  },
  {
    title: "Nocturnal",
    location: [-0.05385874908722579, 0.10604381561279298],
    area: [
      [
        [-0.04727124631562222, 0.09380221366882326],
        [-0.047410721136791054, 0.09531497955322267],
        [-0.048762554004166465, 0.09590506553649902],
        [-0.04748582296346173, 0.09755730628967287],
        [-0.04593014225179433, 0.10010004043579103],
        [-0.043108459220198965, 0.10018587112426759],
        [-0.043698545034151576, 0.10123729705810548],
        [-0.043419595377221516, 0.10164499282836914],
        [-0.04358052787173, 0.10323286056518556],
        [-0.043634172036485584, 0.10541081428527833],
        [-0.04311918805321115, 0.10720252990722658],
        [-0.04299044205684866, 0.10951995849609376],
        [-0.043934579358447676, 0.11032462120056152],
        [-0.045576090774707374, 0.11035680770874025],
        [-0.04584431159083925, 0.1123952865600586],
        [-0.04766821311358781, 0.11191248893737794],
        [-0.051541321478809715, 0.11271715164184572],
        [-0.0536334436291087, 0.11322140693664552],
        [-0.055124751169680825, 0.11252403259277345],
        [-0.057485093962911404, 0.1139509677886963],
        [-0.06152986297498983, 0.10959506034851076],
        [-0.06209849095521379, 0.10774970054626466],
        [-0.06318210274962117, 0.10720252990722658],
        [-0.06332157753341212, 0.10618329048156738],
        [-0.06239889818758602, 0.1051318645477295],
        [-0.06041406465659762, 0.10223507881164552],
        [-0.056583872180415905, 0.10012686252593996],
        [-0.05472242000228772, 0.09900033473968507],
        [-0.05376755402095381, 0.09619474411010744],
        [-0.050505989209599526, 0.09531497955322267],
        [-0.04943310601168556, 0.09306728839874268],
      ],
    ],
  },
  {
    title: "Vortex Isle",
    location: [-0.04149913424997506, 0.10269641876220705],
    area: [
      [
        [-0.045264954626743036, 0.09519159793853761],
        [-0.04420280018059716, 0.09694576263427736],
        [-0.04222869490727133, 0.0965273380279541],
        [-0.04079103125262193, 0.09659171104431152],
        [-0.04026531841722654, 0.09798645973205568],
        [-0.03966450374405397, 0.10110855102539062],
        [-0.0389349430636287, 0.103147029876709],
        [-0.03962158841008418, 0.1044130325317383],
        [-0.040115114749343685, 0.1100510358810425],
        [-0.04116654041867142, 0.11004030704498292],
        [-0.04206239799333701, 0.11052846908569337],
        [-0.04220723724096708, 0.11154234409332277],
        [-0.0436288076200113, 0.11170864105224611],
        [-0.044465656585369086, 0.11244356632232667],
        [-0.044535393998720585, 0.11321067810058595],
        [-0.04583894717453035, 0.1123952865600586],
        [-0.04556536194203869, 0.11035680770874025],
        [-0.04393994377489652, 0.11032462120056152],
        [-0.04299044205684866, 0.10951459407806398],
        [-0.04311918805321115, 0.10720789432525636],
        [-0.0436288076200113, 0.10540544986724855],
        [-0.043575163455255715, 0.10320603847503663],
        [-0.04341423096073451, 0.10165572166442872],
        [-0.04368781620121573, 0.10124266147613527],
        [-0.043108459220198965, 0.10018587112426759],
        [-0.04591941341917653, 0.10010004043579103],
        [-0.04743754321775032, 0.09761095046997072],
        [-0.048741096339808714, 0.09590506553649902],
        [-0.04741608555298545, 0.09531497955322267],
        [-0.0472605174832207, 0.09381294250488281],
      ],
    ],
  },
  {
    title: "Valhalleron",
    location: [-0.032615659859585734, 0.10132312774658205],
    area: [
      [
        [-0.0389349430636287, 0.103147029876709],
        [-0.036317107629781444, 0.10562539100646974],
        [-0.03585576777072961, 0.10638713836669923],
        [-0.03589868310655684, 0.10781407356262208],
        [-0.03434300217022778, 0.10955214500427247],
        [-0.03284096538009259, 0.1118159294128418],
        [-0.03273367703705769, 0.11335015296936037],
        [-0.029053686804201107, 0.112760066986084],
        [-0.026865004508773535, 0.11050701141357423],
        [-0.024740695184511573, 0.1093268394470215],
        [-0.02450466081300332, 0.10765314102172852],
        [-0.02658605480157799, 0.10318994522094728],
        [-0.028367041382595482, 0.09952068328857423],
        [-0.028624533416184216, 0.09535789489746095],
        [-0.029761789890864213, 0.0922679901123047],
        [-0.03130137764677573, 0.09007930755615236],
        [-0.03268003286550845, 0.09070694446563721],
        [-0.03320038132838481, 0.09032070636749269],
        [-0.0358021236009233, 0.09164035320281982],
        [-0.03652095547374266, 0.09241819381713869],
        [-0.03745436401641097, 0.09278833866119386],
        [-0.0390315025658018, 0.0939309597015381],
        [-0.03944992707400146, 0.09477317333221437],
        [-0.04061400550230403, 0.09476780891418457],
        [-0.0452488613776764, 0.09518623352050783],
        [-0.0441867069312888, 0.09694039821624757],
        [-0.04222333049069527, 0.0965273380279541],
        [-0.04079103125262193, 0.09658634662628175],
        [-0.04025995400052325, 0.09798109531402588],
        [-0.03966450374405397, 0.10107636451721193],
      ],
    ],
  },
  {
    title: "Halcyon",
    location: [-0.02368926934459161, 0.09746074676513672],
    area: [
      [
        [-0.01796543568735984, 0.09231626987457277],
        [-0.01861453023588323, 0.09576022624969482],
        [-0.018603801400402936, 0.0970745086669922],
        [-0.01963376960476824, 0.09791135787963869],
        [-0.0183784958548842, 0.10005712509155275],
        [-0.018174647980122496, 0.10128021240234376],
        [-0.01903295481962743, 0.10251402854919434],
        [-0.02066373780277244, 0.10272860527038576],
        [-0.02257347048535244, 0.10411262512207033],
        [-0.02199411338196066, 0.10467052459716797],
        [-0.02264857233192067, 0.10580778121948242],
        [-0.023421048465777364, 0.10695576667785646],
        [-0.02450466081300332, 0.10765314102172852],
        [-0.028356312547853077, 0.09952068328857423],
        [-0.028624533416184216, 0.09536325931549074],
        [-0.02975642547355026, 0.09225189685821535],
        [-0.03128528439508831, 0.09007930755615236],
        [-0.028275846287240506, 0.088689923286438],
        [-0.025776027763647732, 0.0890868902206421],
        [-0.021457671617538497, 0.09232699871063234],
        [-0.02012193161608738, 0.09145259857177734],
      ],
    ],
  },
  {
    title: "Diamantine",
    location: [-0.03828048421257122, 0.10810375213623048],
    area: [
      [
        [-0.040404793251435644, 0.11005640029907227],
        [-0.040490623918535563, 0.11156380176544191],
        [-0.0393909184896292, 0.11199295520782472],
        [-0.038001534536825, 0.11174082756042482],
        [-0.03671407448338129, 0.111311674118042],
        [-0.035533902751408324, 0.11042118072509767],
        [-0.03410696781903691, 0.10989546775817872],
        [-0.03434836658730001, 0.1095360517501831],
        [-0.03589331868958639, 0.10781407356262208],
        [-0.03585576777072961, 0.10639250278472902],
        [-0.036311743212836436, 0.10563075542449953],
        [-0.0389349430636287, 0.10315239429473878],
        [-0.03962158841008418, 0.10440766811370851],
        [-0.040109750332627675, 0.11005640029907227],
      ],
    ],
  },
  {
    title: "Glacierridge Alps",
    location: [-0.06209849095521379, 0.12256622314453126],
    area: [
      [
        [-0.03273367703705769, 0.11334478855133058],
        [-0.03560900458935089, 0.1150023937225342],
        [-0.03567337759325076, 0.11583924293518068],
        [-0.036102530951446776, 0.11635422706604005],
        [-0.03704666833231793, 0.11638641357421876],
        [-0.037057397166131614, 0.11744856834411622],
        [-0.038849112394913066, 0.11867165565490724],
        [-0.040490623918535563, 0.12059211730957033],
        [-0.0403189625842594, 0.12415409088134767],
        [-0.04246472923610927, 0.1258063316345215],
        [-0.04606961707560939, 0.1246476173400879],
        [-0.048000806915515844, 0.1256990432739258],
        [-0.04735707697483436, 0.12690067291259768],
        [-0.0488805711579751, 0.12866020202636722],
        [-0.05167006745929487, 0.1297116279602051],
        [-0.05542515843954543, 0.1298618316650391],
        [-0.059566487072482535, 0.13142824172973636],
        [-0.061840999040399684, 0.13149261474609378],
        [-0.0629997126471907, 0.13355255126953128],
        [-0.06591795420830737, 0.13576269149780276],
        [-0.06763456681193353, 0.138852596282959],
        [-0.07053135044207291, 0.14132022857666018],
        [-0.07263420066847567, 0.14078378677368167],
        [-0.07486579672002532, 0.1383161544799805],
        [-0.0761103406227455, 0.1366209983825684],
        [-0.07675407021354053, 0.13559103012084964],
        [-0.07645366307237389, 0.13305902481079104],
        [-0.07482288141240323, 0.12539863586425784],
        [-0.07443664364190894, 0.12269496917724611],
        [-0.0737070833995351, 0.1183605194091797],
        [-0.07141111432402265, 0.11610746383666994],
        [-0.0696301288872288, 0.11696577072143556],
        [-0.06808517761038542, 0.11715888977050783],
        [-0.06643293799568509, 0.11529207229614259],
        [-0.0655960893384976, 0.11308193206787111],
        [-0.06467341003359886, 0.10969161987304689],
        [-0.06318210274962117, 0.10719716548919678],
        [-0.0621038553700978, 0.10774433612823488],
        [-0.06152986297498983, 0.10958969593048097],
        [-0.057485093962911404, 0.11394560337066652],
        [-0.055124751169680825, 0.11252939701080324],
        [-0.0536334436291087, 0.11321604251861574],
        [-0.050688379351494325, 0.11254012584686279],
        [-0.04766821311358781, 0.11191248893737794],
        [-0.04584431159083925, 0.11238992214202882],
        [-0.044535393998720585, 0.11320531368255617],
        [-0.044465656585369086, 0.11244893074035646],
        [-0.04362344320353701, 0.11170864105224611],
        [-0.042201872824403734, 0.11154770851135254],
        [-0.04206239799333701, 0.11053383350372316],
        [-0.04116117600201902, 0.11004030704498292],
        [-0.040410157668138935, 0.1100510358810425],
        [-0.04048525950183227, 0.11156380176544191],
        [-0.03938555407287503, 0.11199295520782472],
        [-0.03800689895368095, 0.11174082756042482],
        [-0.03671407448338129, 0.11131703853607179],
        [-0.035533902751408324, 0.11042118072509767],
        [-0.03411233223610914, 0.10990083217620851],
        [-0.03284632979722843, 0.1118159294128418],
      ],
    ],
  },
  {
    title: "Dypians City",
    special: true,
    location: [-0.06445883344864528, 0.08557319641113281],
    area: [
      [
        [-0.0649845460801544, 0.09141504764556885],
        [-0.06543515690281666, 0.0914311408996582],
        [-0.06558536050946748, 0.09065330028533937],
        [-0.06595550510965838, 0.09043872356414795],
        [-0.06670652313053373, 0.09101808071136475],
        [-0.0679564316683977, 0.09153306484222413],
        [-0.06905613657841564, 0.09244501590728761],
        [-0.07096050355731272, 0.09189248085021974],
        [-0.07117508011343775, 0.09022951126098633],
        [-0.07256982770378567, 0.08929610252380372],
        [-0.07326720148289784, 0.08816957473754884],
        [-0.07373926988106588, 0.08526206016540527],
        [-0.07177589446527252, 0.08419990539550783],
        [-0.07182953860345138, 0.08187174797058107],
        [-0.07083175762314915, 0.08039116859436037],
        [-0.06997345138634661, 0.07970452308654787],
        [-0.0693082640420238, 0.08036971092224122],
        [-0.06816027940971604, 0.08026242256164552],
        [-0.06730197312467084, 0.08163571357727052],
        [-0.06556390285140722, 0.08191466331481935],
        [-0.06471632535046999, 0.08028388023376466],
        [-0.06355761178203648, 0.08026242256164552],
        [-0.06298898381761352, 0.08129239082336427],
        [-0.06139038818646621, 0.08135139942169191],
        [-0.05971132628082639, 0.0812119245529175],
        [-0.05794643367939806, 0.08133530616760255],
        [-0.05646049062576859, 0.08322358131408693],
        [-0.05553781116535345, 0.0881856679916382],
        [-0.05550562467228362, 0.0890171527862549],
        [-0.05735098357950112, 0.08790135383605958],
        [-0.060097564167868926, 0.08711814880371095],
        [-0.06335912843667148, 0.09076595306396486],
      ],
    ],
  },
  {
    title: "Phenix",
    location: [-0.0698661631056506, 0.10595798492431642],
    area: [
      [
        [-0.07094977472947847, 0.09191393852233888],
        [-0.07127163956336069, 0.09305119514465332],
        [-0.07151840260115645, 0.09420990943908691],
        [-0.07232306467167675, 0.09570121765136719],
        [-0.07366416809078076, 0.09506821632385255],
        [-0.07468340666233887, 0.09478926658630371],
        [-0.07470486431622625, 0.09748220443725586],
        [-0.07648584955188183, 0.09728908538818361],
        [-0.07434008419875422, 0.0996065139770508],
        [-0.07523057683313361, 0.10050773620605469],
        [-0.07436154185280698, 0.10232090950012208],
        [-0.07274148894272153, 0.10282516479492188],
        [-0.07277367542495203, 0.10387659072875978],
        [-0.07513401739172183, 0.10430574417114259],
        [-0.07737634214210241, 0.10318994522094728],
        [-0.0763463748071608, 0.10641932487487794],
        [-0.07848141123408599, 0.10691285133361818],
        [-0.07913586961462493, 0.11126875877380372],
        [-0.07999417567178975, 0.11310338973999025],
        [-0.08020875218329795, 0.1164829730987549],
        [-0.08039114221718112, 0.11949777603149415],
        [-0.08039114221718112, 0.1211071014404297],
        [-0.0789320219234194, 0.12187957763671876],
        [-0.07901785253036867, 0.12336015701293947],
        [-0.07658240899027845, 0.12408971786499025],
        [-0.07473705079703186, 0.12480854988098146],
        [-0.07444200805540623, 0.12268424034118654],
        [-0.07408795676285505, 0.12062966823577882],
        [-0.0737178122267078, 0.1183605194091797],
        [-0.0714164787378889, 0.11610209941864015],
        [-0.06960867123094965, 0.11696577072143556],
        [-0.06808517761038542, 0.11715352535247804],
        [-0.06642757358125907, 0.11528134346008302],
        [-0.06560145375301267, 0.11309266090393068],
        [-0.06467341003359886, 0.10968089103698732],
        [-0.06318746716439068, 0.10719716548919678],
        [-0.06331621311865535, 0.10617792606353761],
        [-0.06239353377274017, 0.10513722896575929],
        [-0.06041406465659762, 0.10222971439361574],
        [-0.05660532984207655, 0.10012686252593996],
        [-0.05911051178449266, 0.099751353263855],
        [-0.060891497593742204, 0.10125339031219482],
        [-0.06326256897108748, 0.10170400142669679],
        [-0.06512402085903457, 0.0985926389694214],
        [-0.06498991049473307, 0.09142041206359865],
        [-0.06544052131734444, 0.0914311408996582],
        [-0.06559072492398253, 0.09064793586730957],
        [-0.06596623393859946, 0.09044408798217775],
        [-0.06670115871614588, 0.09102344512939455],
        [-0.06795106725413708, 0.09153306484222413],
        [-0.06905613657841564, 0.09243965148925783],
      ],
    ],
  },
  {
    title: "Chaosity",
    location: [-0.08377072210751556, 0.08982181549072267],

    area: [
      [
        [-0.07738170655524347, 0.10318994522094728],
        [-0.07704374852624954, 0.10225653648376466],
        [-0.07724223181343584, 0.09991765022277833],
        [-0.07802007171377594, 0.097653865814209],
        [-0.0792538866985377, 0.09606599807739259],
        [-0.07982251446179693, 0.09475708007812501],
        [-0.0828802296562725, 0.09655952453613283],
        [-0.08404967154591607, 0.09679555892944336],
        [-0.0851547404553358, 0.09690284729003906],
        [-0.08477923160615862, 0.0980830192565918],
        [-0.08534785929062916, 0.09957432746887207],
        [-0.08577701225448728, 0.10026097297668458],
        [-0.08687135229048906, 0.1010441780090332],
        [-0.08949991401142898, 0.09829759597778322],
        [-0.09227867905433461, 0.09562611579895021],
        [-0.0942420534522487, 0.09123802185058595],
        [-0.09559388494071372, 0.08701086044311525],
        [-0.09402747702066147, 0.08192539215087892],
        [-0.09037967748438483, 0.07935047149658205],
        [-0.08767601406147743, 0.0805950164794922],
        [-0.08704301346974498, 0.08167862892150879],
        [-0.08541223223552338, 0.08150696754455568],
        [-0.08339521324498005, 0.08161425590515138],
        [-0.08268711080877854, 0.08232235908508301],
        [-0.08283731435690714, 0.08427500724792482],
        [-0.08131382119962251, 0.08650660514831544],
        [-0.07851359771205454, 0.08497238159179688],
        [-0.07711885031069518, 0.083770751953125],
        [-0.07570264521025911, 0.08494019508361818],
        [-0.07373926988106588, 0.08526206016540527],
        [-0.07327256589653507, 0.0881481170654297],
        [-0.07256446329005939, 0.08929610252380372],
        [-0.07118044452732943, 0.09022951126098633],
        [-0.07094977472947847, 0.09190857410430908],
        [-0.07127163956336069, 0.09304583072662355],
        [-0.07151303818731568, 0.09419381618499757],
        [-0.07232306467167675, 0.09569048881530763],
        [-0.07366416809078076, 0.0950521230697632],
        [-0.07468877107581072, 0.09478390216827394],
        [-0.07471022872968537, 0.09747684001922609],
        [-0.0764912139651374, 0.09728908538818361],
        [-0.07435081302577423, 0.09960114955902101],
        [-0.07523057683313361, 0.10050237178802492],
        [-0.07436154185280698, 0.10232627391815187],
        [-0.07274148894272153, 0.1028198003768921],
        [-0.0727790398386656, 0.10387122631073],
        [-0.07512328856489266, 0.1043003797531128],
      ],
    ],
  },
  {
    title: "Nebulae",
    location: [-0.08205411013540762, 0.10140895843505861],
    area: [
      [
        [-0.07848141123408599, 0.10690748691558838],
        [-0.07872280981825858, 0.10529279708862305],
        [-0.07989761624125546, 0.1051318645477295],
        [-0.08127627031120999, 0.10602235794067384],
        [-0.08292850936800286, 0.10638177394866945],
        [-0.08423742597449307, 0.10645687580108644],
        [-0.08646902139857016, 0.10768532752990724],
        [-0.08732196288435494, 0.1057112216949463],
        [-0.08704301346974498, 0.10496020317077637],
        [-0.08727904758993853, 0.10262131690979005],
        [-0.0868767167023579, 0.10105490684509279],
        [-0.08575019019439245, 0.10023951530456544],
        [-0.08534249487855676, 0.09957969188690187],
        [-0.08477923160615862, 0.0980883836746216],
        [-0.08514937604322524, 0.09690821170806886],
        [-0.08404967154591607, 0.09679555892944336],
        [-0.0828855940686884, 0.09656488895416261],
        [-0.07982787887461995, 0.0947624444961548],
        [-0.07925925111143704, 0.09604990482330322],
        [-0.07802007171377594, 0.0976485013961792],
        [-0.07724223181343584, 0.09992301464080812],
        [-0.07704374852624954, 0.10225653648376466],
        [-0.07738707096838454, 0.10318994522094728],
        [-0.0763463748071608, 0.10641932487487794],
      ],
    ],
  },
  {
    title: "Blazing Woods",
    location: [-0.08364197621219294, 0.11351108551025392],
    area: [
      [
        [-0.08646902139857016, 0.10769069194793703],
        [-0.08741852229663284, 0.1083505153656006],
        [-0.08874889639585808, 0.10889768600463869],
        [-0.09014364338397433, 0.11009931564331056],
        [-0.08962865988684132, 0.11110782623291017],
        [-0.08849140463843055, 0.11177301406860353],
        [-0.08804079405863544, 0.11374711990356447],
        [-0.08658167404874459, 0.11497020721435548],
        [-0.08694645405651293, 0.11700868606567384],
        [-0.08559462224543789, 0.11934757232666017],
        [-0.0835776032643726, 0.12063503265380861],
        [-0.08039114221718112, 0.1211071014404297],
        [-0.08039114221718112, 0.11944949626922609],
        [-0.0800049044973976, 0.11312484741210939],
        [-0.07913586961462493, 0.11125802993774414],
        [-0.07847604682108486, 0.10691285133361818],
        [-0.07872280981825858, 0.10528206825256349],
        [-0.07989225182844517, 0.10513722896575929],
        [-0.08129236354910652, 0.10602772235870363],
        [-0.08296069584244736, 0.10639250278472902],
        [-0.08424279038671814, 0.10645687580108644],
      ],
    ],
  },
  {
    title: "Emberhollow",
    location: [-0.03639221676439989, 0.015363732938387113],
    area: [
      [
        [-0.03220259973737792, 0.025738477706909183],
        [-0.03345250893314599, 0.025100111961364746],
        [-0.03419816290932844, 0.024418830871582035],
        [-0.03600597144602951, 0.024145245552062992],
        [-0.03751337260211906, 0.02321720123291016],
        [-0.0387632817261211, 0.021736621856689457],
        [-0.04046380183507002, 0.019859075546264652],
        [-0.04188000782925467, 0.019145607948303223],
        [-0.043290849381355204, 0.01718223094940186],
        [-0.04448711425101179, 0.014752149581909181],
        [-0.04463731791042164, 0.013759732246398928],
        [-0.04072665825255144, 0.011951923370361328],
        [-0.038849112394913066, 0.010632276535034181],
        [-0.03786205969862111, 0.008561611175537111],
        [-0.03593086960841772, 0.007928609848022463],
        [-0.03560900458935089, 0.0072312355041503915],
        [-0.03367781445173179, 0.00682353973388672],
        [-0.032637117528230876, 0.007027387619018555],
        [-0.03290533838584991, 0.008744001388549806],
        [-0.031049250036696667, 0.009495019912719728],
        [-0.030534265980205862, 0.010643005371093752],
        [-0.030426977634791918, 0.01332521438598633],
        [-0.03111362304358627, 0.015267133712768556],
        [-0.032336710166855354, 0.01708030700683594],
        [-0.03259420219095331, 0.017627477645874027],
        [-0.032165048817096246, 0.019934177398681644],
        [-0.03181099728230131, 0.02187609672546387],
        [-0.031703708938197746, 0.023732185363769535],
      ],
    ],
  },
  {
    title: "Cinerea",
    location: [-0.03729343880707748, 0.031242410306551175],
    area: [
      [
        [-0.03755092352027612, 0.02321720123291016],
        [-0.039299723404630116, 0.02494454383850098],
        [-0.04030823375085283, 0.027433633804321293],
        [-0.041456218916972154, 0.029922723770141605],
        [-0.04281878072803033, 0.03206849098205567],
        [-0.04321574755034625, 0.0352013111114502],
        [-0.04134893058439493, 0.035984516143798835],
        [-0.03914951973500432, 0.03679990768432617],
        [-0.036370751799295144, 0.03849506378173829],
        [-0.03434300217022778, 0.03926753997802735],
        [-0.032744405871367545, 0.03800153732299805],
        [-0.0312745555606258, 0.03681063652038574],
        [-0.031317470898463144, 0.035330057144165046],
        [-0.03211140464522894, 0.03432154655456544],
        [-0.03158569175954262, 0.032862424850463874],
        [-0.032572744522295444, 0.030298233032226566],
        [-0.032358167835551385, 0.02799153327941895],
        [-0.03219723532020391, 0.025738477706909183],
        [-0.0334686021844899, 0.025100111961364746],
        [-0.03419279849225621, 0.02442419528961182],
        [-0.036022064696940866, 0.024139881134033207],
      ],
    ],
  },
  {
    title: "Peridotum",
    location: [-0.0428295168584054, 0.023775140409090238],
    area: [
      [
        [-0.04331230704735412, 0.017198324203491214],
        [-0.04493236081192739, 0.018668174743652347],
        [-0.04684209301798397, 0.020256042480468753],
        [-0.04727124631562222, 0.022873878479003906],
        [-0.04703521200224178, 0.02699375152587891],
        [-0.046048159410424686, 0.028216838836669925],
        [-0.04497527614295835, 0.02802371978759766],
        [-0.043988223522948816, 0.030319690704345707],
        [-0.042813416311492436, 0.0320577621459961],
        [-0.04146158333359911, 0.029933452606201175],
        [-0.04035651350115696, 0.027540922164916996],
        [-0.039294358987863216, 0.02494454383850098],
        [-0.037540194686513324, 0.023211836814880375],
        [-0.040469166251760585, 0.019842982292175296],
        [-0.041906829912236765, 0.019134879112243656],
      ],
    ],
  },
  {
    title: "Lunaris",
    location: [-0.04892349378390083, 0.02424720919571133],
    area: [
      [
        [-0.044631953494023685, 0.013759732246398928],
        [-0.04842996020595332, 0.01296043395996094],
        [-0.05115508353576397, 0.01229524612426758],
        [-0.0516056944690841, 0.01536369323730469],
        [-0.05167006745929487, 0.018324851989746097],
        [-0.05244254333676069, 0.02156496047973633],
        [-0.05109071054504431, 0.024225711822509766],
        [-0.050275319323532706, 0.02903223037719727],
        [-0.04890202882228197, 0.03244400024414063],
        [-0.04918097845764787, 0.03656387329101563],
        [-0.048784011668511505, 0.038951039314270026],
        [-0.04737853463962467, 0.038688182830810554],
        [-0.04585504042345704, 0.03761529922485352],
        [-0.04484653014978914, 0.03615617752075196],
        [-0.04375218919883083, 0.03512620925903321],
        [-0.043210383133833796, 0.03519594669342042],
        [-0.042813416311492436, 0.032052397727966316],
        [-0.04396676585715345, 0.03034651279449463],
        [-0.044503207500256534, 0.02911806106567383],
        [-0.044980640559330855, 0.028029084205627445],
        [-0.046048159410424686, 0.02822220325469971],
        [-0.04703521200224178, 0.026999115943908695],
        [-0.04727124631562222, 0.02288997173309326],
        [-0.04684209301798397, 0.020239949226379398],
        [-0.045039649139453894, 0.01876473426818848],
        [-0.04330157821435466, 0.017198324203491214],
        [-0.04449247866742246, 0.014736056327819826],
      ],
    ],
  },
  {
    title: "Obsidian City",
    location: [-0.05506038548039516, 0.01914028323135586],
    area: [
      [
        [-0.0502806837394981, 0.029026865959167484],
        [-0.052313797357827645, 0.028560161590576172],
        [-0.05350469765265649, 0.028195381164550785],
        [-0.05577920986279196, 0.026521682739257816],
        [-0.057227602026494975, 0.022777318954467777],
        [-0.058783282457853726, 0.01981616020202637],
        [-0.061197269247893885, 0.016490221023559574],
        [-0.06289242435134251, 0.014119148254394533],
        [-0.06499527490932447, 0.01403331756591797],
        [-0.06572483528666832, 0.011994838714599611],
        [-0.06301044147675516, 0.01094341278076172],
        [-0.06141184584625669, 0.010042190551757814],
        [-0.05851506169688539, 0.01032114028930664],
        [-0.055403700777464954, 0.010750293731689455],
        [-0.053386680507346296, 0.011951923370361328],
        [-0.05114971911987491, 0.012289881706237795],
        [-0.051611058884934995, 0.015352964401245119],
        [-0.05167006745929487, 0.018308758735656742],
        [-0.05244254333676069, 0.02156496047973633],
        [-0.05109071054504431, 0.02422034740447998],
      ],
    ],
  },
  {
    title: "Highlands",
    location: [-0.05244255063407269, 0.035576860074617585],
    area: [
      [
        [-0.05349396882128547, 0.02820074558258057],
        [-0.05477606415809958, 0.028823018074035648],
        [-0.056074252713879565, 0.03128528594970704],
        [-0.057200779949721316, 0.03315746784210206],
        [-0.056895008273585644, 0.03571629524230958],
        [-0.056412210886927175, 0.03769040107727051],
        [-0.055221310649437375, 0.04091978073120118],
        [-0.053944579737723215, 0.04154205322265625],
        [-0.052099220725725516, 0.042566657066345215],
        [-0.050071471516554986, 0.04184246063232422],
        [-0.0497281488928612, 0.04025459289550782],
        [-0.0487893760845914, 0.038951039314270026],
        [-0.04918634287370232, 0.03655850887298585],
        [-0.04890202882228197, 0.03246009349822999],
        [-0.0502806837394981, 0.02903223037719727],
        [-0.05173444044944203, 0.028699636459350586],
        [-0.052324526189414945, 0.02856552600860596],
      ],
    ],
  },
  {
    title: "Turquessa",
    location: [-0.06364344241762346, 0.02081394195556641],
    area: [
      [
        [-0.057195415534366585, 0.03315210342407227],
        [-0.058643807662307444, 0.03466486930847169],
        [-0.06031750518565757, 0.032894611358642585],
        [-0.06124018456762751, 0.03163933753967286],
        [-0.06160496478405296, 0.0301051139831543],
        [-0.06316064509054313, 0.02939701080322266],
        [-0.0661432596159427, 0.02800226211547852],
        [-0.06823538120893217, 0.025566816329956055],
        [-0.06939409466762383, 0.021049976348876957],
        [-0.07136819901309281, 0.018496513366699222],
        [-0.07296679431783887, 0.016436576843261722],
        [-0.07365343926358263, 0.014795064926147463],
        [-0.07344959154641105, 0.01270294189453125],
        [-0.07275221777013594, 0.011576414108276367],
        [-0.07090685941811606, 0.01231670379638672],
        [-0.06960867123094965, 0.012252330780029297],
        [-0.06865380551670601, 0.011308193206787111],
        [-0.06703375240749239, 0.011394023895263674],
        [-0.06633637853595331, 0.011361837387084961],
        [-0.06571947087216598, 0.011994838714599611],
        [-0.06499527490932447, 0.014038681983947756],
        [-0.06290315318093243, 0.014119148254394533],
        [-0.06047843763713951, 0.017482638359069828],
        [-0.058783282457853726, 0.01982688903808594],
        [-0.057227602026494975, 0.022777318954467777],
        [-0.05577384544731001, 0.026532411575317386],
        [-0.05350469765265649, 0.02820074558258057],
        [-0.054786792989254325, 0.028817653656005863],
        [-0.05584358284861357, 0.030861496925354007],
        [-0.056143990114826896, 0.0314086675643921],
        [-0.05643366854863871, 0.03189146518707276],
      ],
    ],
  },
  {
    title: "Phobia",
    location: [-0.05900858789607327, 0.04192829132080079],
    area: [
      [
        [-0.05863844324709266, 0.03466486930847169],
        [-0.06087540434873395, 0.034954547882080085],
        [-0.0642764433507312, 0.035930871963500984],
        [-0.06774185509765483, 0.036971569061279304],
        [-0.0699412649021569, 0.03822684288024903],
        [-0.06902931450773593, 0.04001855850219727],
        [-0.06512402085903457, 0.04127383232116699],
        [-0.06364344241762346, 0.045146942138671875],
        [-0.06346105231682142, 0.045930147171020515],
        [-0.06143330350604717, 0.04605889320373535],
        [-0.06151913414512004, 0.052109956741333015],
        [-0.06046770880705343, 0.05263566970825195],
        [-0.05928753748499035, 0.05437374114990235],
        [-0.057635297591940596, 0.05444884300231934],
        [-0.05703984748879949, 0.052131414413452155],
        [-0.05677699113499278, 0.04998564720153809],
        [-0.055199852987280563, 0.04910051822662354],
        [-0.053869477918545926, 0.048322677612304694],
        [-0.05303799348579084, 0.04464268684387208],
        [-0.05210458514153825, 0.04256129264831544],
        [-0.05398213064728005, 0.04152059555053711],
        [-0.05522667506498294, 0.04091978073120118],
        [-0.05641757530235824, 0.03768503665924073],
        [-0.056905737104358714, 0.03566801548004151],
        [-0.057200779949721316, 0.0331789255142212],
      ],
    ],
  },
  {
    title: "Nightglass",
    location: [-0.07235525115421258, 0.02750873565673828],
    area: [
      [
        [-0.06993053607409365, 0.038221478462219245],
        [-0.07164714853339896, 0.0360703468322754],
        [-0.07314918438257341, 0.03473997116088868],
        [-0.0747155931431572, 0.03268003463745118],
        [-0.07630345950100179, 0.030899047851562503],
        [-0.07885692014220522, 0.0294184684753418],
        [-0.08160349948046236, 0.02802371978759766],
        [-0.0828909584811043, 0.026435852050781253],
        [-0.08252617843514386, 0.024032592773437503],
        [-0.08126017707331346, 0.022466182708740238],
        [-0.08121726177220516, 0.02042770385742188],
        [-0.0795221073425634, 0.018882751464843753],
        [-0.07810590232258265, 0.016264915466308597],
        [-0.07619617123540705, 0.015342235565185549],
        [-0.07365343926358263, 0.014789700508117678],
        [-0.07297215873151428, 0.016436576843261722],
        [-0.07098732562687286, 0.01899540424346924],
        [-0.06939409466762383, 0.021049976348876957],
        [-0.06823538120893217, 0.025556087493896484],
        [-0.06614862403039416, 0.02800226211547852],
        [-0.06383119693249527, 0.029080510139465336],
        [-0.06161569361391003, 0.0301051139831543],
        [-0.06124018456762751, 0.03163933753967286],
        [-0.060296047525421816, 0.03292143344879151],
        [-0.05865453649274974, 0.034654140472412116],
        [-0.060843217858717456, 0.034943819046020515],
        [-0.06362198475874897, 0.03572702407836915],
        [-0.06677626051761384, 0.036681890487670905],
        [-0.06773112626909542, 0.03696620464324951],
      ],
    ],
  },
  {
    title: "Vertical Coast",
    location: [-0.07561681459661797, 0.0423574447631836],
    area: [
      [
        [-0.0676774821262475, 0.05644440650939942],
        [-0.0682782965227118, 0.05396604537963868],
        [-0.07099269004079, 0.05459904670715333],
        [-0.07309554024597209, 0.055886507034301765],
        [-0.07636783246022123, 0.05224943161010743],
        [-0.07641074776631665, 0.050060749053955085],
        [-0.07994053154375048, 0.047550201416015625],
        [-0.08254763608499828, 0.048365592956542976],
        [-0.08560535106951907, 0.048022270202636726],
        [-0.0893175240204833, 0.048000812530517585],
        [-0.09193535674405269, 0.04675626754760743],
        [-0.0919031702772924, 0.045275688171386726],
        [-0.09143110209482957, 0.0439453125],
        [-0.09167786500916893, 0.040469169616699226],
        [-0.09104486448640363, 0.03980398178100586],
        [-0.09037967748438483, 0.036660432815551765],
        [-0.08874889639585808, 0.033216476440429694],
        [-0.08947845636548027, 0.031017065048217777],
        [-0.08764382759096409, 0.029515027999877933],
        [-0.08617397874177628, 0.028259754180908203],
        [-0.08408185801944457, 0.026891827583312992],
        [-0.0828855940686884, 0.026425123214721683],
        [-0.08159813506788106, 0.028029084205627445],
        [-0.07897493722691948, 0.02935409545898438],
        [-0.07630882391427007, 0.030882954597473148],
        [-0.07459221163320305, 0.03283560276031495],
        [-0.07314381996892345, 0.03475069999694825],
        [-0.07164714853339896, 0.03606498241424561],
        [-0.069935900488138, 0.03822684288024903],
        [-0.06902931450773593, 0.04002392292022706],
        [-0.06511865644446863, 0.04127383232116699],
        [-0.06364344241762346, 0.045146942138671875],
        [-0.06346105231682142, 0.04593551158905029],
        [-0.06143330350604717, 0.04605889320373535],
        [-0.06151913414512004, 0.05212604999542237],
        [-0.060462344392004035, 0.052630305290222175],
        [-0.059276808654662554, 0.05438446998596192],
        [-0.05764602642257372, 0.05444884300231934],
        [-0.05813955262938221, 0.056084990501403816],
        [-0.0582039256125576, 0.05752265453338624],
        [-0.05862235000142285, 0.057833790779113776],
        [-0.05906759646306698, 0.05668580532073975],
        [-0.061165082758068215, 0.05693256855010987],
        [-0.06286023786253463, 0.05668044090270997],
        [-0.06406186676411099, 0.05715787410736085],
        [-0.06558536050946748, 0.05639076232910157],
        [-0.06675480286006247, 0.05582213401794434],
      ],
    ],
  },
  {
    title: "Ignisia",
    location: [-0.07823464823547466, 0.05909442901611329],
    area: [
      [
        [-0.06925998431505222, 0.0614762306213379],
        [-0.07054744368396422, 0.06164252758026124],
        [-0.07214067460360898, 0.06234526634216309],
        [-0.07502672912323914, 0.06438374519348146],
        [-0.07798788523542571, 0.06643295288085939],
        [-0.0816893300817884, 0.06913661956787111],
        [-0.08497235044332213, 0.07118582725524904],
        [-0.08853431993146024, 0.07262349128723146],
        [-0.09059425393798151, 0.07216215133666994],
        [-0.09186025498823205, 0.07100343704223634],
        [-0.09100194919631277, 0.06932973861694337],
        [-0.09187098381050668, 0.06809592247009279],
        [-0.09050842335669297, 0.06587505340576173],
        [-0.08954282930328822, 0.06377220153808595],
        [-0.08896347285900914, 0.060328245162963874],
        [-0.0882768281725442, 0.05675554275512696],
        [-0.08857723522443904, 0.05292534828186035],
        [-0.08933898166652107, 0.04979252815246583],
        [-0.08930679519745806, 0.04800617694854736],
        [-0.08559998665748483, 0.048022270202636726],
        [-0.08253690726007742, 0.048365592956542976],
        [-0.07994053154375048, 0.04755556583404542],
        [-0.07641074776631665, 0.05005538463592529],
        [-0.07636783246022123, 0.05222797393798829],
        [-0.07309554024597209, 0.05588114261627198],
        [-0.07098196121296847, 0.05459904670715333],
        [-0.0682782965227118, 0.05396604537963868],
        [-0.0676774821262475, 0.056439042091369636],
        [-0.0683802203928112, 0.05801618099212647],
        [-0.06880400911347165, 0.059695243835449226],
      ],
    ],
  },
  {
    title: "Obsidian Shores",
    location: [-0.11243812973627809, 0.08561611175537111],
    area: [
      [
        [-0.11255614670544917, 0.06858944892883302],
        [-0.11516324871988622, 0.07190465927124025],
        [-0.11578551994753018, 0.07529497146606447],
        [-0.11790982506965601, 0.07939338684082033],
        [-0.1217292823542988, 0.08158206939697267],
        [-0.12520541716608244, 0.08216142654418947],
        [-0.12593497626602645, 0.08329868316650392],
        [-0.12398233274716265, 0.08538007736206056],
        [-0.12398233274716265, 0.08816957473754884],
        [-0.12187948571972362, 0.09016513824462892],
        [-0.12054911302555295, 0.09083032608032227],
        [-0.1192831131433293, 0.09241819381713869],
        [-0.11951914702410342, 0.0934267044067383],
        [-0.12029162152882912, 0.09462833404541017],
        [-0.11934748602009605, 0.09600162506103517],
        [-0.11778107930926525, 0.09606599807739259],
        [-0.11650435048661574, 0.09734272956848146],
        [-0.11317841798952889, 0.0969243049621582],
        [-0.11090390909383673, 0.09607672691345216],
        [-0.10871523055746857, 0.09685993194580078],
        [-0.10664456885490707, 0.09531497955322267],
        [-0.10603302625277591, 0.09420990943908691],
        [-0.10437005946678872, 0.0932443141937256],
        [-0.10240668566446529, 0.09187102317810059],
        [-0.10101193917432212, 0.0897359848022461],
        [-0.1011943291033742, 0.08733272552490234],
        [-0.10165566833109865, 0.08579850196838379],
        [-0.10358685572414078, 0.08447885513305665],
        [-0.10464900874029655, 0.08335232734680176],
        [-0.10665529767237271, 0.08238673210144044],
        [-0.10620468733529208, 0.07930755615234376],
        [-0.10472411046733952, 0.07849216461181642],
        [-0.1046919240129198, 0.07628202438354494],
        [-0.10658019594998605, 0.07516622543334962],
        [-0.108382637236504, 0.07398605346679689],
        [-0.10979884102932498, 0.07239818572998048],
        [-0.11068933277384879, 0.07024168968200685],
      ],
    ],
  },
  {
    title: "Pyrosia",
    location: [-0.12381067177155128, 0.061368942260742194],
    area: [
      [
        [-0.11254541789009542, 0.06858944892883302],
        [-0.11226110428162456, 0.06622374057769777],
        [-0.11158518889949184, 0.06532788276672365],
        [-0.11012070551837147, 0.06486654281616212],
        [-0.10883324754144831, 0.06361663341522218],
        [-0.10857575593944811, 0.06201267242431641],
        [-0.1089190780749522, 0.06059646606445313],
        [-0.11065714632571384, 0.060338973999023444],
        [-0.1114296210712457, 0.058901309967041016],
        [-0.11241130769777287, 0.057426095008850105],
        [-0.1136773078886757, 0.055655837059021],
        [-0.11501840972528836, 0.05427718162536622],
        [-0.11711589287110791, 0.05262494087219238],
        [-0.1189505199442436, 0.051766633987426765],
        [-0.12063494352391807, 0.05012512207031251],
        [-0.12071004520976651, 0.048290491104125984],
        [-0.12004485884219204, 0.04611253738403321],
        [-0.11932602839452834, 0.04463195800781251],
        [-0.12112846888426645, 0.043398141860961914],
        [-0.12324204478191744, 0.043526887893676765],
        [-0.12461533258510364, 0.04398822784423828],
        [-0.12664307772586128, 0.043838024139404304],
        [-0.1281451110613416, 0.04367709159851074],
        [-0.1293789240925049, 0.043516159057617194],
        [-0.1300333814151532, 0.044932365417480476],
        [-0.13095605892316575, 0.0455009937286377],
        [-0.13305890520948466, 0.045897960662841804],
        [-0.13558017475503756, 0.047324895858764655],
        [-0.1360200557979256, 0.04950284957885743],
        [-0.13508664967301315, 0.05086541175842286],
        [-0.13524758176608476, 0.052099227905273444],
        [-0.1367174281665508, 0.051455497741699226],
        [-0.1384340369874415, 0.05298972129821778],
        [-0.13902412124098854, 0.0594162940979004],
        [-0.13962493428401232, 0.06546735763549806],
        [-0.13659941130558217, 0.06920099258422853],
        [-0.13271558337898304, 0.07057428359985353],
        [-0.12921799196139386, 0.07138967514038087],
        [-0.12878883960679008, 0.077054500579834],
        [-0.12827385677173406, 0.08149623870849611],
        [-0.12593497626602645, 0.08329331874847412],
        [-0.12520541716608244, 0.08215606212615967],
        [-0.12176683319572816, 0.08158743381500246],
        [-0.11960200852633833, 0.08035269790468648],
        [-0.11791758488343444, 0.07939783149538472],
        [-0.1165311725213043, 0.07673799991607667],
        [-0.11579088435461547, 0.07529497146606447],
        [-0.11516861312707329, 0.07189929485321046],
      ],
    ],
  },
  {
    title: "Island Zero",
    special: true,
    location: [-0.06949065412123623, 0.07364809513092042],
    area: [
      [
        [-0.06709812537975211, 0.07344961166381837],
        [-0.06724296456701524, 0.07306337356567384],
        [-0.0672376001526783, 0.07266104221343996],
        [-0.06719468483796995, 0.0721406936645508],
        [-0.06733952402495319, 0.07199048995971681],
        [-0.067521914111659, 0.07204949855804445],
        [-0.06772576185480936, 0.07161498069763185],
        [-0.06820855913779446, 0.07133603096008302],
        [-0.06842850012066062, 0.0713789463043213],
        [-0.06861089020325715, 0.07151842117309572],
        [-0.06890056856827993, 0.07131457328796388],
        [-0.06951747619164877, 0.0712341070175171],
        [-0.07009683290887032, 0.07122337818145753],
        [-0.07061181665147855, 0.07136821746826173],
        [-0.07090685941811606, 0.0713413953781128],
        [-0.07102487652425454, 0.07150232791900636],
        [-0.07089613059028183, 0.07161498069763185],
        [-0.07115362245787105, 0.07176518440246583],
        [-0.0715988688088446, 0.07177054882049562],
        [-0.07185636067251538, 0.07194757461547853],
        [-0.07148621611804812, 0.07247328758239747],
        [-0.07181880977580798, 0.07293999195098878],
        [-0.07173834356851423, 0.07335305213928224],
        [-0.07201192867276593, 0.07347106933593751],
        [-0.07181344536199263, 0.07468879222869874],
        [-0.07184026743106935, 0.07486581802368164],
        [-0.07161496205036696, 0.07534325122833253],
        [-0.07123945308007425, 0.07547199726104738],
        [-0.07097659679905136, 0.07607281208038331],
        [-0.07068691844680168, 0.07598161697387695],
        [-0.07066009637706341, 0.07567048072814943],
        [-0.07039724009274546, 0.07601916790008546],
        [-0.06968913744193285, 0.0760781764984131],
        [-0.06826220328005717, 0.07608890533447267],
        [-0.06796716049690621, 0.07587969303131104],
        [-0.06807444878188962, 0.07569730281829835],
        [-0.06825147445161225, 0.07565975189208986],
        [-0.06801007581094029, 0.07517158985137941],
        [-0.0676774821262475, 0.0750643014907837],
        [-0.06769893978339175, 0.07474780082702638],
        [-0.06711958303715082, 0.07416844367980958],
        [-0.06702838799312999, 0.07377147674560548],
      ],
    ],
  },
];

const firstParcel = [
  {
    title: "Genesis Land #1",
    location: [-0.05465473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #2",
    location: [-0.05465473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #3",
    location: [-0.05465473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #4",
    location: [-0.05465473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #5",
    location: [-0.05465473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #6",
    location: [-0.05465473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #7",
    location: [-0.05465473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #8",
    location: [-0.05465473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #9",
    location: [-0.05465473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #10",
    location: [-0.05465473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #11",
    location: [-0.05480473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #12",
    location: [-0.05480473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #13",
    location: [-0.05480473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #14",
    location: [-0.05480473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #15",
    location: [-0.05480473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #16",
    location: [-0.05480473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #17",
    location: [-0.05480473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #18",
    location: [-0.05480473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #19",
    location: [-0.05480473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #20",
    location: [-0.05480473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #21",
    location: [-0.05495473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #22",
    location: [-0.05495473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #23",
    location: [-0.05495473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #24",
    location: [-0.05495473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #25",
    location: [-0.05495473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #26",
    location: [-0.05495473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #27",
    location: [-0.05495473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #28",
    location: [-0.05495473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #29",
    location: [-0.05495473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #30",
    location: [-0.05495473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #31",
    location: [-0.05510473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #32",
    location: [-0.05510473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #33",
    location: [-0.05510473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #34",
    location: [-0.05510473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #35",
    location: [-0.05510473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #36",
    location: [-0.05510473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #37",
    location: [-0.05510473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #38",
    location: [-0.05510473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #39",
    location: [-0.05510473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #40",
    location: [-0.05510473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #41",
    location: [-0.05525473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #42",
    location: [-0.05525473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #43",
    location: [-0.05525473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #44",
    location: [-0.05525473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #45",
    location: [-0.05525473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #46",
    location: [-0.05525473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #47",
    location: [-0.05525473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #48",
    location: [-0.05525473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #49",
    location: [-0.05525473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #50",
    location: [-0.05525473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #51",
    location: [-0.05540473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #52",
    location: [-0.05540473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #53",
    location: [-0.05540473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #54",
    location: [-0.05540473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #55",
    location: [-0.05540473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #56",
    location: [-0.05540473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #57",
    location: [-0.05540473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #58",
    location: [-0.05540473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #59",
    location: [-0.05540473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #60",
    location: [-0.05540473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #61",
    location: [-0.05555473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #62",
    location: [-0.05555473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #63",
    location: [-0.05555473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #64",
    location: [-0.05555473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #65",
    location: [-0.05555473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #66",
    location: [-0.05555473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #67",
    location: [-0.05555473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #68",
    location: [-0.05555473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #69",
    location: [-0.05555473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #70",
    location: [-0.05555473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #71",
    location: [-0.05570473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #72",
    location: [-0.05570473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #73",
    location: [-0.05570473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #74",
    location: [-0.05570473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #75",
    location: [-0.05570473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #76",
    location: [-0.05570473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #77",
    location: [-0.05570473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #78",
    location: [-0.05570473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #79",
    location: [-0.05570473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #80",
    location: [-0.05570473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #81",
    location: [-0.05585473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #82",
    location: [-0.05585473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #83",
    location: [-0.05585473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #84",
    location: [-0.05585473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #85",
    location: [-0.05585473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #86",
    location: [-0.05585473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #87",
    location: [-0.05585473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #88",
    location: [-0.05585473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #89",
    location: [-0.05585473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #90",
    location: [-0.05585473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #91",
    location: [-0.05600473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #92",
    location: [-0.05600473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #93",
    location: [-0.05600473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #94",
    location: [-0.05600473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #95",
    location: [-0.05600473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #96",
    location: [-0.05600473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #97",
    location: [-0.05600473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #98",
    location: [-0.05600473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #99",
    location: [-0.05600473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #100",
    location: [-0.05600473025383857, 0.09360871894836427],
  },
];
const secondParcel = [
  {
    title: "Genesis Land #101",
    location: [-0.05765473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #102",
    location: [-0.05765473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #103",
    location: [-0.05765473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #104",
    location: [-0.05765473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #105",
    location: [-0.05765473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #106",
    location: [-0.05765473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #107",
    location: [-0.05765473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #108",
    location: [-0.05765473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #109",
    location: [-0.05765473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #110",
    location: [-0.05765473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #111",
    location: [-0.05780473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #112",
    location: [-0.05780473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #113",
    location: [-0.05780473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #114",
    location: [-0.05780473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #115",
    location: [-0.05780473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #116",
    location: [-0.05780473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #117",
    location: [-0.05780473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #118",
    location: [-0.05780473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #119",
    location: [-0.05780473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #120",
    location: [-0.05780473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #121",
    location: [-0.05795473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #122",
    location: [-0.05795473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #123",
    location: [-0.05795473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #124",
    location: [-0.05795473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #125",
    location: [-0.05795473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #126",
    location: [-0.05795473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #127",
    location: [-0.05795473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #128",
    location: [-0.05795473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #129",
    location: [-0.05795473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #130",
    location: [-0.05795473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #131",
    location: [-0.05810473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #132",
    location: [-0.05810473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #133",
    location: [-0.05810473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #134",
    location: [-0.05810473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #135",
    location: [-0.05810473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #136",
    location: [-0.05810473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #137",
    location: [-0.05810473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #138",
    location: [-0.05810473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #139",
    location: [-0.05810473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #140",
    location: [-0.05810473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #141",
    location: [-0.05825473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #142",
    location: [-0.05825473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #143",
    location: [-0.05825473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #144",
    location: [-0.05825473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #145",
    location: [-0.05825473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #146",
    location: [-0.05825473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #147",
    location: [-0.05825473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #148",
    location: [-0.05825473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #149",
    location: [-0.05825473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #150",
    location: [-0.05825473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #151",
    location: [-0.05840473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #152",
    location: [-0.05840473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #153",
    location: [-0.05840473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #154",
    location: [-0.05840473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #155",
    location: [-0.05840473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #156",
    location: [-0.05840473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #157",
    location: [-0.05840473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #158",
    location: [-0.05840473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #159",
    location: [-0.05840473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #160",
    location: [-0.05840473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #161",
    location: [-0.05855473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #162",
    location: [-0.05855473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #163",
    location: [-0.05855473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #164",
    location: [-0.05855473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #165",
    location: [-0.05855473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #166",
    location: [-0.05855473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #167",
    location: [-0.05855473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #168",
    location: [-0.05855473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #169",
    location: [-0.05855473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #170",
    location: [-0.05855473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #171",
    location: [-0.05870473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #172",
    location: [-0.05870473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #173",
    location: [-0.05870473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #174",
    location: [-0.05870473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #175",
    location: [-0.05870473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #176",
    location: [-0.05870473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #177",
    location: [-0.05870473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #178",
    location: [-0.05870473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #179",
    location: [-0.05870473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #180",
    location: [-0.05870473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #181",
    location: [-0.05885473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #182",
    location: [-0.05885473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #183",
    location: [-0.05885473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #184",
    location: [-0.05885473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #185",
    location: [-0.05885473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #186",
    location: [-0.05885473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #187",
    location: [-0.05885473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #188",
    location: [-0.05885473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #189",
    location: [-0.05885473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #190",
    location: [-0.05885473025383857, 0.09360871894836427],
  },
  {
    title: "Genesis Land #191",
    location: [-0.05900473025383857, 0.09225871894836427],
  },
  {
    title: "Genesis Land #192",
    location: [-0.05900473025383857, 0.09240871894836427],
  },
  {
    title: "Genesis Land #193",
    location: [-0.05900473025383857, 0.09255871894836427],
  },
  {
    title: "Genesis Land #194",
    location: [-0.05900473025383857, 0.09270871894836427],
  },
  {
    title: "Genesis Land #195",
    location: [-0.05900473025383857, 0.09285871894836427],
  },
  {
    title: "Genesis Land #196",
    location: [-0.05900473025383857, 0.09300871894836427],
  },
  {
    title: "Genesis Land #197",
    location: [-0.05900473025383857, 0.09315871894836427],
  },
  {
    title: "Genesis Land #198",
    location: [-0.05900473025383857, 0.09330871894836427],
  },
  {
    title: "Genesis Land #199",
    location: [-0.05900473025383857, 0.09345871894836427],
  },
  {
    title: "Genesis Land #200",
    location: [-0.05900473025383857, 0.09360871894836427],
  },
];

const leaderboards = [
  {
    title: "BNB Chain Leaderboard",
    location: [-0.06775258392621424, 0.08520841598510744],
  },
  {
    title: "Manta Leaderboard",
    location: [-0.06855724606138884, 0.08495092391967773],
  },
  {
    title: "Taiko Leaderboard",
    location: [-0.0689971280229101, 0.08639931678771973],
  },
  {
    title: "CORE Leaderboard",
    location: [-0.0681495505812457, 0.08628129959106447],
  },
  {
    title: "SKALE Leaderboard",
    location: [-0.06927607755737608, 0.08556246757507326],
  },
  {
    title: "VICTION Leaderboard",
    location: [-0.06696937943514361, 0.08530497550964355],
  },
];

let midleLastDay = new Date("2024-09-10T13:00:00.000+02:00");
let cookieLastDay = new Date("2024-10-01T14:00:00.000+02:00");
let mantaLastDay = new Date("2024-11-18T14:00:00.000+02:00");
let taikoLastDay = new Date("2024-11-17T14:00:00.000+02:00");

// const dummyBetaPassData2 = [
//   {
//     title: "CORE",
//     logo: coreIcon,
//     eventStatus: "Expired",
//     totalRewards: "$20,000 in CORE Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Jul 01, 2024",
//     userEarnUsd: coreEarnUsd,
//     userEarnCrypto: coreEarnToken,
//     userEarnPoints: corePoints,
//     popupInfo: {
//       title: "CORE",
//       chain: "CORE Chain",
//       linkState: "core",
//       rewards: "CORE",
//       status: "Expired",
//       id: "event12",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in CORE Rewards",
//       eventDuration: coreLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "",
//       eventDate: "Jul 01, 2024",
//     },
//   },
//   {
//     title: "VICTION",
//     logo: victionIcon,
//     eventStatus: "Expired",
//     totalRewards: "$20,000 in VIC Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Find",
//     eventDate: "Jul 01, 2024",
//     userEarnUsd: victionEarnUsd,
//     userEarnCrypto: victionEarnToken,
//     userEarnPoints: victionPoints,
//     popupInfo: {
//       title: "VICTION",
//       chain: "VICTION Chain",
//       linkState: "viction",
//       rewards: "VIC",
//       status: "Expired",
//       id: "event14",
//       eventType: "Explore & Find",
//       totalRewards: "$20,000 in VIC Rewards",
//       eventDuration: coreLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "",
//       eventDate: "Jul 01, 2024",
//     },
//   },
//   {
//     title: "Base",
//     logo: baseIcon,
//     eventStatus: "Coming Soon",
//     totalRewards: "$20,000 in ETH Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Find",
//     eventDate: "Oct 07, 2024",
//     userEarnUsd: 0,
//     userEarnCrypto: 0,
//     userEarnPoints: 0,
//     popupInfo: {
//       title: "Base",
//       chain: "Base",
//       linkState: "base",
//       rewards: "ETH",
//       status: "Coming Soon",
//       id: "event24",
//       eventType: "Explore & Find",
//       totalRewards: "$20,000 in ETH Rewards",
//       eventDuration: baseLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "",
//       eventDate: "Oct 07, 2024",
//     },
//   },
//   {
//     title: "BNB Chain",
//     logo: bnbIcon,
//     eventStatus: "Expired",
//     totalRewards: "$20,000 in BNB Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Jun 12, 2024",
//     userEarnUsd: bnbEarnUsd,
//     userEarnCrypto: bnbEarnToken,
//     userEarnPoints: bnbPoints,
//     popupInfo: {
//       title: "BNB Chain",
//       chain: "BNB Chain",
//       linkState: "bnb",
//       rewards: "BNB",
//       status: "Expired",
//       id: "event20",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in BNB Rewards",
//       eventDuration: bnbLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "/news",
//       eventDate: "Jun 12, 2024",
//     },
//   },
//   {
//     title: "Immutable",
//     logo: immutableLogo,
//     eventStatus: "Live",
//     totalRewards: "$20,000 in IMX Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Aug 15, 2024",
//     backgroundImage: immutableBg,
//     userEarnUsd: immutableEarnUsd,
//     userEarnCrypto: immutableEarnToken,
//     userEarnPoints: immutablePoints,
//     popupInfo: {
//       title: "Immutable",
//       chain: "Immutable",
//       linkState: "immutable",
//       rewards: "IMX",
//       status: "Live",
//       id: "event15",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in IMX Rewards",
//       eventDuration: immutableLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "https://medium.com/@worldofdypians/625a2926c94b",
//       eventDate: "Aug 15, 2024",
//     },
//   },
//   {
//     title: "Taiko",
//     logo: taikoLogo,
//     eventStatus: "Live",
//     totalRewards: "$20,000 in TAIKO Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Aug 19, 2024",
//     backgroundImage: taikoBg,
//     userEarnUsd: taikoEarnUsd,
//     userEarnCrypto: taikoEarnToken,
//     userEarnPoints: taikoPoints,
//     popupInfo: {
//       title: "TAIKO",
//       chain: "Taiko",
//       linkState: "taiko",
//       rewards: "TAIKO",
//       status: "Live",
//       id: "event22",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in TAIKO Rewards",
//       eventDuration: taikoLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "",
//       eventDate: "Aug 19, 2024",
//     },
//   },
//   {
//     title: "Manta",
//     logo: mantaLogo,
//     eventStatus: "Live",
//     totalRewards: "$20,000 in MANTA Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Aug 20, 2024",
//     backgroundImage: mantaBg,
//     userEarnUsd: mantaEarnUsd,
//     userEarnCrypto: mantaEarnToken,
//     userEarnPoints: mantaPoints,
//     popupInfo: {
//       title: "Manta",
//       chain: "Manta",
//       linkState: "manta",
//       rewards: "MANTA",
//       status: "Live",
//       id: "event21",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in MANTA Rewards",
//       eventDuration: mantaLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "",
//       eventDate: "Aug 20, 2024",
//     },
//   },
//   {
//     title: "Cookie3",
//     logo: cookie3Logo,
//     eventStatus: "Live",
//     totalRewards: "$20,000 in COOKIE Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Aug 26, 2024",
//     backgroundImage: upcomingCookie,
//     userEarnUsd: cookieEarnUsd,
//     userEarnCrypto: cookieEarnToken,
//     userEarnPoints: cookiePoints,
//     popupInfo: {
//       title: "Cookie3",
//       chain: "BNB Chain",
//       linkState: "cookie3",
//       rewards: "Cookie3",
//       status: "Live",
//       id: "event23",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in COOKIE Rewards",
//       eventDuration: cookieLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore: "",
//       eventDate: "Aug 26, 2024",
//     },
//   },
//   // {
//   //   title: "SEI",
//   //   logo: seiLogo,
//   //   eventStatus: "Coming Soon",
//   //   totalRewards: "$20,000 in SEI Rewards",
//   //   myEarnings: 0.0,
//   //   eventType: "Explore & Find",
//   //   eventDate: "XXX XX, XXXX",
//   //   backgroundImage: seiBg,
//   //   popupInfo: {
//   //     title: "SEI",
//   //     chain: "SEI Chain",
//   //     linkState: "sei",
//   //     rewards: "SEI",
//   //     status: "Coming Soon",
//   //     id: "event13",
//   //     eventType: "Explore & Find",
//   //     totalRewards: "$20,000 in SEI Rewards",
//   //     eventDuration: dypius2LastDay,
//   //     minRewards: "1",
//   //     maxRewards: "100",
//   //     minPoints: "5,000",
//   //     maxPoints: "50,000",
//   //     learnMore:
//   //       "/news/65dc8229039c5118d5c8782b/Dypius-Treasure-Hunt:-Magic-Egg-is-Live",
//   //     eventDate: "XXX XX, XXXX",
//   //   },
//   // },
//   {
//     title: "SKALE",
//     logo: skaleLogo,
//     eventStatus: "Expired",
//     totalRewards: "$20,000 in SKL Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Apr 15, 2024",
//     backgroundImage: upcomingSkale,
//     userEarnUsd: skaleEarnUsd,
//     userEarnCrypto: skaleEarnToken,
//     userEarnPoints: skalePoints,
//     popupInfo: {
//       title: "SKALE",
//       chain: "SKALE Nebula Hub",
//       linkState: "skale",
//       rewards: "SKL",
//       status: "Expired",
//       id: "event11",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in SKL Rewards",
//       eventDuration: skaleLastDay,
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore:
//         "/news/661d1671299713edd050794b/SKALE-Treasure-Hunt-Event-Live-in-the-World-of-Dypians",
//       eventDate: "Apr 15, 2024",
//     },
//   },
//   {
//     title: "Dypius Premium",
//     logo: dypiusPremium,
//     eventStatus: "Expired",
//     totalRewards: "$50,000 in BNB Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Find",
//     eventDate: "Feb 26, 2024",
//     backgroundImage: upcomingDyp2,
//     activeTab: "dypiusv2",
//     userEarnUsd: dypiusPremiumEarnUsd,
//     userEarnCrypto: dypiusPremiumEarnTokens,
//     userEarnPoints: dypiusPremiumPoints,
//     popupInfo: {
//       title: "Dypius Premium",
//       chain: "BNB Chain",
//       linkState: "dypius",
//       rewards: "BNB",
//       status: "Expired",
//       id: "event9",
//       eventType: "Explore & Find",
//       totalRewards: "$50,000 in BNB Rewards",
//       eventDuration: dypius2LastDay,
//       minRewards: "1",
//       maxRewards: "100",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore:
//         "/news/65dc8229039c5118d5c8782b/Dypius-Treasure-Hunt:-Magic-Egg-is-Live",
//       eventDate: "Feb 26, 2024",
//       activeTab: "dypiusv2",
//     },
//   },
//   {
//     title: "CMC",
//     logo: cmc,
//     eventStatus: "Expired",
//     totalRewards: "$20,000 in BNB Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Dec 26, 2023",
//     userEarnUsd: cmcuserEarnUsd,
//     userEarnCrypto: cmcuserEarnETH,
//     userEarnPoints: cmcuserPoints,
//     backgroundImage: cmcUpcoming,
//     popupInfo: {
//       title: "CoinMarketCap",
//       chain: "BNB Chain",
//       linkState: "coinmarketcap",
//       rewards: "BNB",
//       status: "Expired",
//       id: "event8",
//       eventType: "Explore & Mine",
//       totalRewards: "$20,000 in BNB Rewards",
//       eventDuration: cmcLastDay,
//       minRewards: "1",
//       maxRewards: "100",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       eventDate: "Dec 26, 2023",
//       learnMore:
//         "/news/658ae3cc148c5ffee9c4ffa7/CoinMarketCap-Treasure-Hunt-Event",
//     },
//   },
//   {
//     title: "Dogecoin",
//     chain: "BNB Chain",
//     linkState: "doge",
//     rewards: "DOGE",
//     status: "Expired",
//     eventStatus: "Expired",
//     id: "event7",
//     eventType: "Explore & Mine",
//     date: "Dec 22, 2023",
//     eventDate: "Dec 22, 2023",
//     logo: doge,
//     totalRewards: "$10,000 in DOGE Rewards",
//     eventDuration: dogeLastDay,
//     userEarnCrypto: dogeEarnBNB,
//     userEarnPoints: dogeUserPoints,
//     backgroundImage: upcomingDoge,
//     minRewards: "1",
//     maxRewards: "100",
//     minPoints: "5,000",
//     maxPoints: "50,000",
//     learnMore: "/news/65857c6b148c5ffee9c203ec/Dogecoin-Treasure-Hunt-Event",
//     userEarnUsd: dogeEarnUSD,
//     popupInfo: {
//       title: "Dogecoin",
//       chain: "BNB Chain",
//       linkState: "doge",
//       rewards: "DOGE",
//       status: "Expired",
//       id: "event7",
//       eventStatus: "Expired",
//       eventType: "Explore & Mine",
//       totalRewards: "$10,000 in DOGE Rewards",
//       eventDuration: dogeLastDay,
//       minRewards: "1",
//       maxRewards: "100",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore:
//         "/news/65857c6b148c5ffee9c203ec/Dogecoin-Treasure-Hunt-Event",
//       eventDate: "Dec 22, 2023",
//     },
//   },
//   {
//     title: "Base",
//     logo: baseLogo,
//     eventStatus: "Expired",
//     totalRewards: "$10,000 in ETH Rewards",
//     myEarnings: 126.45,
//     eventType: "Explore & Mine",
//     eventDate: "Nov 01, 2023",
//     backgroundImage: baseUpcoming,
//     userEarnUsd: baseEarnUSD,
//     userEarnCrypto: baseEarnETH,
//     userEarnPoints: baseUserPoints,
//     popupInfo: {
//       eventType: "Explore & Mine",
//       title: "Base",
//       chain: "Base Chain",
//       linkState: "base",
//       rewards: "ETH",
//       status: "Expired",
//       id: "event4",
//       date: "Nov 01, 2023",
//       totalRewards: "$10,000 in ETH Rewards",
//       eventDuration: baseLastDay,
//       eventDate: "Nov 01, 2023",
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "30,000",
//       learnMore: "/news/65422043b3f3545e95018290/Base-Treasure-Hunt-Event",
//     },
//   },
//   {
//     title: "CoinGecko",
//     logo: coingecko,
//     eventStatus: "Expired",
//     totalRewards: "$10,000 in BNB Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Mine",
//     eventDate: "Ended",
//     backgroundImage: coingeckoUpcoming,
//     userEarnUsd: userEarnUsd,
//     userEarnCrypto: userEarnETH,
//     userEarnPoints: userPoints,
//     popupInfo: {
//       title: "CoinGecko",
//       chain: "BNB Chain",
//       linkState: "coingecko",
//       rewards: "BNB",
//       status: "Expired",
//       id: "event3",
//       eventType: "Explore & Mine",
//       totalRewards: "$10,000 in BNB Rewards",
//       eventDuration: coingeckoLastDay,
//       minRewards: "1",
//       maxRewards: "100",
//       minPoints: "5,000",
//       maxPoints: "50,000",
//       learnMore:
//         "/news/6511853f7531f3d1a8fbba67/CoinGecko-Treasure-Hunt-Event",
//     },
//   },
//   {
//     title: "Dypius",
//     logo: dypius,
//     eventStatus: "Expired",
//     totalRewards: "300,000 in DYPv2 Rewards",
//     myEarnings: 0.0,
//     eventType: "Explore & Find",
//     eventDate: "Ended",
//     backgroundImage: upcomingDyp,
//     userEarnUsd: dypiusEarnUsd,
//     userEarnCrypto: dypiusEarnTokens,
//     userEarnPoints: 0,
//     popupInfo: {
//       title: "Dypius",
//       chain: "BNB Chain",
//       linkState: "dypius",
//       rewards: "DYP",
//       status: "Expired",
//       id: "event5",
//       eventType: "Explore & Find",
//       totalRewards: "300,000 in DYPv2 Rewards",
//       eventDuration: dypiusLastDay,
//       minRewards: "25",
//       maxRewards: "50",
//       learnMore: "/news/655b40db87aee535424a5915/Dypius-Treasure-Hunt-Event",
//       eventDate: "Ended",
//     },
//   },
//   {
//     title: "Gate.io",
//     logo: gate,
//     eventStatus: "Expired",
//     totalRewards: "$2,000 in BNB Rewards",
//     myEarnings: 0,
//     eventType: "Explore & Mine",
//     eventDate: "Ended",
//     backgroundImage: gateUpcoming,
//     userEarnUsd: gateEarnUSD,
//     userEarnCrypto: gateEarnBnb,
//     userEarnPoints: gateUserPoints,
//     popupInfo: {
//       eventType: "Explore & Mine",
//       title: "Gate.io",
//       chain: "BNB Chain",
//       linkState: "gate",
//       rewards: "BNB",
//       status: "Expired",
//       id: "event6",
//       totalRewards: "$2,000 in BNB Rewards",
//       eventDuration: gateLastDay,
//       eventDate: "Ended",
//       date: "Oct 20, 2023",
//       minRewards: "0.5",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "20,000",
//       learnMore: "/news/653290f5b3f3545e9500f557/Gate-Treasure-Hunt-Event",
//     },
//   },
//   {
//     title: "Conflux",
//     logo: conflux,
//     eventStatus: "Expired",
//     totalRewards: "$2,000 in CFX Rewards",
//     myEarnings: 0,
//     eventType: "Explore & Mine",
//     eventDate: "Ended",
//     backgroundImage: confluxUpcoming,
//     userEarnUsd: confluxEarnUSD,
//     userEarnCrypto: confluxEarnCFX,
//     userEarnPoints: confluxUserPoints,
//     popupInfo: {
//       eventType: "Explore & Mine",
//       title: "Conflux",
//       chain: "Conflux Network",
//       linkState: "conflux",
//       rewards: "CFX",
//       status: "Expired",
//       id: "event1",
//       totalRewards: "$2,000 in CFX Rewards",
//       eventDuration: confluxLastDay,
//       eventDate: "Ended",
//       minRewards: "1",
//       maxRewards: "20",
//       minPoints: "5,000",
//       maxPoints: "20,000",
//       learnMore: "/news/65200e247531f3d1a8fce737/Conflux-Treasure-Hunt-Event",
//     },
//   },
// ];

const challenges = [
  {
    title: "Critical Hit",
    duration: "--",
    image: "criticalHitDummy.png",
    type: "event",
    infoType: "Daily Opportunity",
    marker: markers.criticalHitMarker,
    popupImage: "https://cdn.worldofdypians.com/wod/criticalHitPopup.webp",

    location: [-0.06178739905312879, 0.09370563518485311],
    desc: "Break the Genesis Gem located on your land to unleash unique benefits and claim powerful rewards. A perfect chance to boost your progress.",
    link: "/account/challenges/critical-hit",
    popupDesc:
      "As a Genesis Land NFT holder, you can participate in the daily Critical Hit event to earn points and rewards. Each day, you need to log in to the game and visit your land. On your land, you have a Genesis Gem, which you need to break with a pickaxe. Once broken, it gives you either points that are added to your leaderboard rank on BNB Chain or direct rewards in BNB.",
    secondaryTitle: "What is Genesis Land?",
    thirdDesc:
      "Genesis Land is a 125x125 area in World of Dypians, available to those who own a Genesis Land NFT. Benefits include exclusive rewards, Land NFT staking pool, and special in-game events like Critical Hit.",
    workList: [
      "Hold Genesis Land NFT to access the event.",
      "Earn 30,000-80,000 points by destroying the Gem",
      "Receive rewards ranging from $20 to $7,000 ",
      "Rewards are distributed monthly, and you can destroy the Gem once every 24 hours (00:00 UTC).",
    ],
    tips: [
      "Recommended Hero Level: Any",
      "Use your pickaxe to break the Genesis Gem efficiently.",
      "Check your Genesis Land daily to ensure you don't miss a gem reset.",
    ],
  },
  {
    title: "Dragon Ruins",
    duration: "--",
    image: "dragonRuinsDummy.png",
    type: "event",
    infoType: "Legendary Beast Siege",
    marker: markers.dragonMarker,
    location: [-0.07085058232491746, 0.07364278764323107],
    popupImage: "https://cdn.worldofdypians.com/wod/dragonRuinsPopup.webp",
    day: 1,
    popupDesc:
      "The Dragon Ruins challenge invites players to summon and battle a fearsome dragon for exclusive rewards. This high-stakes event offers a chance to test your combat skills and teamwork. The dragon can only be summoned on Mondays and must be defeated before the end of the day at 00:00 UTC. Players can only purchase access once per day, giving you a single opportunity to emerge victorious.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "The event is available exclusively on Mondays.",
      "The Dragon must be defeated within the day, with the timer resetting at 00:00 UTC.",
      "Rewards include 16,000 points and up to 200 stars.",
    ],
    tips: [
      "Recommended Hero Level: 10 and above",
      "Craft plenty of health potions and equip fire-resistant gear to counter the dragon's fiery breath.",
      "Use ranged weapons to attack from a distance, avoiding its powerful melee strikes.",
    ],
    link: "/account/challenges/dragon-ruins",
  },
  {
    title: "Cold Bite",
    duration: "--",
    image: "coldBiteDummy.png",
    type: "event",
    infoType: "Legendary Beast Siege",
    day: 2,

    marker: markers.coldFuryMarker,
    location: [-0.06136893052665028, 0.128016471862793],
    popupImage: "https://cdn.worldofdypians.com/wod/coldBitePopup.webp",
    popupDesc:
      "Cold Bite pits players against the ferocious Polar Bear, a frost-bound menace that rewards resilience and strategy. This chilling event is available on Tuesdays and runs until 00:00 UTC. Players can only buy access once per day, so make every move count as you battle this frosty foe.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "The event is available exclusively on Tuesdays.",
      "The Polar Bear must be defeated within the day, with the timer resetting at 00:00 UTC.",
      "Rewards include 30,000 points and up to 300 stars.",
    ],
    tips: [
      "Recommended Hero Level: 15 and above",
      "Craft plenty of health potions and equip frost-resistant armor to mitigate the bear's ice attacks.",
      "Focus on evading its slow but powerful swipes and counterattacking with precision.",
    ],
    link: "/account/challenges/cold-bite",
  },
  {
    title: "Fury Beast",
    duration: "--",
    image: "furyBeastDummy.png",
    type: "event",
    infoType: "Legendary Beast Siege",
    day: 3,

    marker: markers.furyBeastMarker,
    location: [-0.0658321235765625, 0.05362272262573243],
    popupImage: "https://cdn.worldofdypians.com/wod/furyBeastPopup.webp",

    popupDesc:
      "Fury Beast throws you into a battle against the Gorilla, a relentless opponent that tests your endurance and tactical skills. Available only on Wednesdays, the event runs until 00:00 UTC. Access can be purchased once per day, so strategic preparation is key to claiming victory and rewards.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "The event is available exclusively on Wednesdays.",
      "The Gorilla must be defeated within the day, with the timer resetting at 00:00 UTC.",
      "Rewards include 60,000 points and up to 400 stars.",
    ],
    tips: [
      "Recommended Hero Level: 18 and above",
      "Craft plenty of health potions and focus on agility to dodge the Gorilla’s ground-pounding attacks.",
      "Aim for weak points like the head to deal maximum damage quickly.",
    ],
    link: "/account/challenges/fury-beast",
  },
  {
    title: "Wing Storm",
    duration: "--",
    image: "wingStormDummy.png",
    type: "event",
    infoType: "Legendary Beast Siege",
    day: 4,

    marker: markers.eagleMarker,
    location: [-0.051476948488459, 0.034632682800292976],
    popupImage: "https://cdn.worldofdypians.com/wod/wingStormPopup.webp",

    popupDesc:
      "Take to the skies in Wing Storm, an exhilarating battle against a swift and deadly Eagle. Available exclusively on Thursdays, this event tests your precision and speed as you fight a high-flying adversary. Access can be purchased once per day, with the event running until 00:00 UTC.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "The event is available exclusively on Thursdays.",
      "The Eagle must be defeated within the day, with the timer resetting at 00:00 UTC.",
      "Rewards include 70,000 points and up to 500 stars.",
    ],
    tips: [
      "Recommended Hero Level: 22 and above",
      "Craft plenty of health potions and use ranged weapons or magic to counter the Eagle’s aerial mobility.",
      "Stay mobile and anticipate its swift movements to avoid being caught off-guard.",
    ],
    link: "/account/challenges/wing-storm",
  },
  {
    title: "Scorpion King",
    duration: "--",
    image: "scorpionKingDummy.png",
    type: "event",
    infoType: "Legendary Beast Siege",

    marker: markers.scorpionMarker,
    location: [-0.022588222633792756, 0.07132530212402345],
    popupImage: "https://cdn.worldofdypians.com/wod/scorpionKingPopup.webp",
    day: 6,

    popupDesc:
      "Face off against the venomous Scorpion King in this thrilling event. Available only on Saturdays, this battle tests your resistance to poison and your ability to exploit the Scorpion King’s weaknesses. Access can be purchased once per day, with the event running until 00:00 UTC.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "The event is available exclusively on Saturdays.",
      "The Scorpion must be defeated within the day, with the timer resetting at 00:00 UTC.",
      "Rewards include 120,000 points and up to 1,000 stars.",
    ],
    tips: [
      "Recommended Hero Level: 40 and above",
      "Craft plenty of health potions and target the tail to disable its poison strikes and reduce the threat.",
      "Equip high-damage weapons to end the fight quickly before the poison accumulates.",
    ],
    link: "/account/challenges/scorpion-king",
  },
  {
    title: "Stone Eye",
    duration: "--",
    image: "stoneEyeDummy.png",
    type: "event",
    infoType: "Legendary Beast Siege",
    day: 0,

    marker: markers.cyclopsMarker,
    location: [-0.11007779025336882, 0.09102344512939455],
    popupImage: "https://cdn.worldofdypians.com/wod/stoneEyePopup.webp",

    popupDesc:
      "Stone Eye challenges players to battle the Cyclops, a colossal enemy with devastating attacks. This event is available exclusively on Sundays and ends at 00:00 UTC. Only one access purchase is allowed per day, so prepare carefully for this epic showdown.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "The event is available exclusively on Sundays.",
      "The Cyclop must be defeated within the day, with the timer resetting at 00:00 UTC.",
      "Rewards include 80,000 points and up to 600 stars.",
    ],
    tips: [
      "Recommended Hero Level: 30 and above",
      "Craft plenty of health potions and equip high-defense gear to withstand its crushing attacks.",
      "Attack its legs to slow it down and exploit openings for critical hits.",
    ],
    link: "/account/challenges/stone-eye",
  },
  {
    title: "Maze Day",
    duration: "--",
    image: "mazeDayDummy.png",
    type: "event",
    infoType: "The Great Hunt",
    marker: markers.mazeMarker,
    location: [-0.06562827582557527, 0.08779406547546388],
    popupImage: "https://cdn.worldofdypians.com/wod/mazeGardenPopup.webp",
    day: 5,

    popupDesc:
      "Explore the enigmatic BNB Chain Maze, a labyrinth filled with twists and turns leading to the hidden gem at the center. This event is only accessible to WOD token holders and runs exclusively on Fridays. Navigate the maze carefully and claim your prize before 00:00 UTC.",
    workList: [
      "Hold at least 400 WOD tokens to participate.",
      "The event is available exclusively on Fridays.",
      "Players must find their way to the maze’s center and collect the gem to earn rewards.",
      "Rewards include up to 200,000 points, 800 stars, and $100.",
    ],
    tips: [
      "Recommended Hero Level: 15 and above",
      "Focus on observation to spot clues, gates, and shortcuts.",
      "Plan your route and mark your path to avoid retracing your steps.",
    ],
    link: "/account/challenges/maze-day",
  },
  {
    title: "Puzzle Madness",
    duration: "--",
    image: "puzzleMadnessDummy.png",
    type: "event",
    infoType: "The Great Hunt",
    marker: markers.puzzleMarker,
    link: "/account/challenges/puzzle-madness",
    popupImage: "https://cdn.worldofdypians.com/wod/puzzleMadnessPopup.webp",

    popupDesc:
      "In the Puzzle Madness event, players search for 10 hidden pieces across the Island Zero and Dypians City maps. These pieces hold points that contribute to the BNB Chain leaderboard. One piece contains a multiplier (x2 to x8) that activates only after all pieces are found, significantly boosting your score.",
    secondaryDesc:
      "Players have two hours to find the pieces. Points are added to the leaderboards even if not all pieces are found. You can extend time by purchasing another bundle.",
    secondaryTitle: "CAWS NFT Utility",
    thirdDesc:
      "Holding a CAWS NFT gives you an advantage. Your cat companion helps detect hidden pieces with an exclamation mark above its head. However, the cat cannot detect pieces on top or inside buildings, so players must thoroughly explore.",
    workList: [
      "Purchase the bundle from the Challenge & Events.",
      "Find 10 pieces within the two-hour limit in the  Island Zero and Dypians City maps ",
      "An indicator will guide you on whether pieces are located making your search easier",
    ],
  },
  {
    title: "Treasure Hunt",
    duration: "--",
    image: "treasureHuntDummy.png",
    type: "event",
    infoType: "The Great Hunt",
    marker: markers.mazeMarker,
    popupImage: "https://cdn.worldofdypians.com/wod/treasureHuntPopup.webp",

    popupDesc:
      "Embark on a daily Treasure Hunt event where you’ll explore partner areas to uncover hidden items. These items could be Chests, Eggs, or Magma, each offering unique rewards. The event challenges players to carefully investigate specific areas each day to find the hidden treasures. Once collected, these treasures will either grant leaderboard points or direct rewards, making this event a must for adventurers looking to climb the ranks or earn exciting prizes.",
    workList: [
      "The event occurs daily, requiring players to visit partner areas to search for hidden items.",
      "Hidden treasures include Chests, Eggs, or Magma, and their locations change each day.",
      "Players must collect the treasures and reveal the rewards they contain.",
      "Rewards include up to 50,000 points.",
    ],
    tips: [
      "Explore each area thoroughly and pay attention to visual or auditory clues indicating the presence of treasures.",
      "Plan your exploration route efficiently to maximize the number of treasures you can find in a single session.",
    ],
    link: "/account/challenges/treasure-hunt",
  },
  {
    title: "Explorer Hunt",
    duration: "--",
    image: "explorerHuntDummy.png",
    type: "event",
    infoType: "The Great Hunt",
    marker: markers.mazeMarker,
    popupImage: "https://cdn.worldofdypians.com/wod/explorerHuntPopup.webp",

    popupDesc:
      "Defend the world from the alien explorers who have landed to assess the terrain before their invasion. Players will hear an alert signaling the arrival of these intruders in a specific area, and your task is to defend the city by defeating them. The event features three levels of explorers, each more powerful and challenging than the last. Be prepared to strategize and face increasingly formidable opponents as you protect your homeland.",
    workList: [
      "Alerts will notify players of the arrival of alien explorers in a designated area.",
      "Players must travel to the location and engage in combat with the explorers.",
      "There are three levels of explorers to defeat, each with higher difficulty and rewards.",
    ],
    tips: [
      "Recommended Hero Level: 20 and above",
      "Upgrade your weapons and armor to handle the increasing difficulty of higher-level explorers.",
      "Stock up on health potions to sustain yourself during prolonged battles.",
    ],
    link: "/account/challenges/explorer-hunt",
  },
  {
    title: "Great Collection",
    duration: "--",
    image: "greatCollectionDummy.png",
    type: "event",
    infoType: "The Great Hunt",
    marker: markers.mazeMarker,
    popupImage: "https://cdn.worldofdypians.com/wod/greatCollectionPopup.webp",

    popupDesc:
      "The Great Collection is a thrilling event where players are tasked with gathering rare and unique partner branded coins scattered across the game. This event challenges your exploration and problem-solving skills as you work to collect as many coins as possible. Compete against other players to amass the largest collection and earn exclusive rewards based on your ranking.",
    workList: [
      "Rare collectible items are hidden across the map, in both common and hard-to-reach locations.",
      "Players must explore the maps to retrieve these partner branded coins.",
      "The collected amount contributes to unlocking more rewards in the future.",
    ],
    tips: [
      "Recommended Hero Level: 1 and above",
      "Prioritize exploring areas that are less crowded to maximize your chances of finding hidden coins.",
      "Use mounts or movement speed boosts to traverse large zones quickly.",
    ],
    link: "/account/challenges/great-collection",
  },
];

const allAreas = [
  ...bearAreas,
  ...deerAreas,
  ...boarAreas,
  ...areas,
  ...seas,
  ...chainAreas,
  ...bosses,
  ...quests,
  ...teleports,
  ...craftingTables,
  ...regions,
  ...leaderboards,
  ...dypiansTransport,
  ...mines,
  ...animals,
];

export {
  bearAreas,
  deerAreas,
  boarAreas,
  areas,
  seas,
  allAreas,
  hypatiaBorders,
  keplerBorders,
  calderaBorders,
  quests,
  bosses,
  chainAreas,
  teleports,
  craftingTables,
  regions,
  challenges,
  firstParcel,
  secondParcel,
  leaderboards,
  dypiansTransport,
  mines,
  animals,
};
